/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import * as St from './styles';
import ContainerFlex from '~/components/containerFlex';
import Collapse from '~/components/collapse';
import Button from '~/components/button';
import GeneralData from './components/GeneralData';
import BankData from './components/BankData';
import MDRData from './components/MDRData';
import banknowApi from '~/services/banknow/api';
import BANKNOW_ENDPOINTS from '~/services/banknow/endpoints';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';

const dialogInitialState = { open: false, message: '' };
export default function ParametersData({
  parameters = null,
}: {
  parameters: unknown;
}) {
  const [form, setForm] = useState(parameters);
  const [dialog, setDialog] = useState(dialogInitialState);
  const handleUpdateCompany = async (payload) => {
    try {
      const response = await banknowApi.put(
        BANKNOW_ENDPOINTS.COMPANIES.updateCompanyInterface(parameters?.id),
        payload,
      );
      setDialog({ open: true, message: response.data.message });
      return response;
    } catch (error) {
      setDialog({ open: true, message: error.response.data.message });
    }
  };
  console.log(form);
  return (
    <St.BoxCard>
      <Dialog
        open={dialog.open}
        icon={icons.exclamation}
        positiveAction={() => {
          setDialog(dialogInitialState);
        }}
        message={dialog.message}
        positiveLabel="Entendi"
      />
      <Collapse collapseHeight={70} initialState="open" title="1. Gerais">
        <GeneralData
          onChange={(e) =>
            setForm((prev) => ({ ...prev, generalParametersData: e }))
          }
          defaultValues={form.generalParametersData}
        />
        <ContainerFlex end row gap="30">
          <Button
            fitContent
            onClick={() =>
              handleUpdateCompany({
                companyData: {
                  generalParametersData: form.generalParametersData,
                },
              })
            }
          >
            Gravar
          </Button>
        </ContainerFlex>
      </Collapse>
      <Collapse
        collapseHeight={70}
        initialState="close"
        title="2. Domicilio bancário"
      >
        <BankData
          onChange={(e) => setForm((prev) => ({ ...prev, bankData: e }))}
          defaultValues={form.bankData}
        />
        <ContainerFlex end row gap="30">
          <Button
            fitContent
            onClick={() =>
              handleUpdateCompany({
                bankData: [{ ...form.bankData, type: 'CHECKING' }],
              })
            }
            // disabled={isButtonDisabled}
          >
            Gravar
          </Button>
        </ContainerFlex>
      </Collapse>
      <Collapse
        collapseHeight={70}
        initialState="close"
        title="3. Taxa de administração (MDR)"
      >
        <MDRData
          onChange={(e) =>
            setForm((prev) => ({ ...prev, splitsModalities: e }))
          }
          defaultValues={parameters.splitsModalities}
        />
        <ContainerFlex end row gap="30">
          <Button
            fitContent
            onClick={() =>
              handleUpdateCompany({
                companyData: {
                  splitsModalities: form.splitsModalities?.map((e) => ({
                    modality: e.modality,
                    percentualSplit: e.percentualSplit,
                  })),
                },
              })
            } // disabled={isButtonDisabled}
          >
            Gravar
          </Button>
        </ContainerFlex>
      </Collapse>
    </St.BoxCard>
  );
}
