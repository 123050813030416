/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import { SplitsModality } from '~/services/banknow/types';
import { currencyMask } from '~/utils/masks';
import {
  Table,
  TBody,
  TbodyRow,
  Td,
  Th,
  Thead,
  TheadRow,
  TableInput,
} from '../styles';

const MDRData = ({
  defaultValues,
  onChange,
}: {
  defaultValues: SplitsModality[];
  onChange: (values: SplitsModality[]) => void;
}) => {
  const [values, setValues] = useState(
    defaultValues?.map((e) => ({
      ...e,
      unmask: String(e.percentualSplit).replace('.', ','),
    })),
  );
  const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const mapped = values?.map((p) => {
      if (p.modality === name) {
        return {
          modality: name,
          percentualSplit: Number(value?.replace(',', '.')),
          unmask: value,
        };
      }
      return p;
    });
    setValues(mapped.slice(0, 12));
  };
  useEffect(() => {
    onChange(values);
  }, [values]);
  const format = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 2,
  });
  const formatInput = (input) => {
    let value = input.replace(/\D/g, '');

    if (!value) {
      value = '';
      return;
    }
    let number = parseFloat(value) / 100;
    if (number > 100) number = 100;

    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.group('TESTE');
    const value = formatInput(e.target.value);

    handleChangeForm({
      target: {
        name: e.target.name,
        value,
      },
    });

    console.groupEnd();
  };

  return (
    <ContainerFlex gap={12} row space marginTop={20}>
      <Table>
        <Thead>
          <TheadRow>
            <Th>MODALIDADE</Th>
            <Th>TAXA BASE</Th>
            <Th>MODALIDADE</Th>
            <Th>TAXA BASE</Th>
          </TheadRow>
        </Thead>
        <TBody>
          {values?.slice(0, 6).map((leftItem, i) => {
            const rightItem = values[i + 6];
            return (
              <TbodyRow
                key={leftItem.modality}
                style={{ borderBottom: '1px solid black' }}
              >
                <Td>{leftItem.modality.replace('_', ' ')}</Td>
                <Td>
                  <div>
                    <TableInput
                      value={leftItem.unmask}
                      onChange={handleChange}
                      type="text"
                      name={leftItem.modality}
                    />
                    <span>%</span>
                  </div>
                </Td>

                {rightItem ? (
                  <>
                    <Td>{rightItem.modality.replace('_', ' ')}</Td>
                    <Td>
                      <div>
                        <TableInput
                          value={rightItem.unmask}
                          onChange={handleChange}
                          type="text"
                          name={rightItem.modality}
                        />
                        <span>%</span>
                      </div>
                    </Td>
                  </>
                ) : (
                  <>
                    <Td>-</Td>
                    <Td>-</Td>
                  </>
                )}
              </TbodyRow>
            );
          })}
        </TBody>
      </Table>
    </ContainerFlex>
  );
};

export default MDRData;
