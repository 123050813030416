const USERS = {
  postGenerateInternalToken: '/v1/users/generate-internal-token',
};
const ACCOUNTS = {
  postGetBanks: '/v1/accounts/list-banks',
};

const COMPANIES = {
  getCompanies: (params: string | URLSearchParams) =>
    `/v1/companies/list/ALL?${params}&brand=TEUCARD`,
  getCompanyDetails: (id: string) => `/v1/companies/find-company-by-code/${id}`,
  getBusinessGroups: `/v1/companies/list-business-groups`,
  updateCompanyInterface: (id: string) =>
    `/v1/companies/update-company-interface/${id}`,
  createCompanyInterface: `/v1/companies/create-company-interface`,
  deleteCompany: (id: string) => `/v1/companies/delete-company/${id}`,
};

const REPORT = {
  listSalesByDate: (company, params: string | URLSearchParams) =>
    `v1/box-billings/list-sales-by-date-backoffice/${company}?${params}`,
  listSalesByPayoutDate: (company, params: string | URLSearchParams) =>
    `v1/box-billings/list-sales-payout-by-date-backoffice/${company}?${params}`,
};

const EMPLOYEES = {
  sendNewPasswordByDocument: (document: string) =>
    `v1/employees/send-new-password-by-document/${document}`,
};

const BANKNOW_ENDPOINTS = {
  USERS,
  COMPANIES,
  ACCOUNTS,
  REPORT,
  EMPLOYEES,
};

export default BANKNOW_ENDPOINTS;
