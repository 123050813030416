/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/newInput';
import Select from '~/components/select';
import { GeneralParametersData } from '~/services/banknow/types';

const CAPTURE_FORMS = [
  { id: 'POS TEUPAG', value: 'POS TEUPAG' },
  { id: 'SITEF', value: 'SITEF' },
  { id: 'POSTEUPAGSITEF', value: 'AMBOS' },
];
const CONDITIONS = [
  { id: 'ANTECIPADO', value: 'ANTECIPADO' },
  { id: 'FLUXO', value: 'NO FLUXO' },
];
const GeneralData = ({
  defaultValues,
  onChange,
}: {
  defaultValues: GeneralParametersData;
  onChange: (values: GeneralParametersData) => void;
}) => {
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <ContainerFlex gap={12} row space marginTop={20}>
      <ContainerFlex>
        <Input
          mask={MaskTypes.NUMERIC}
          value={values?.mcc}
          name="mcc"
          label="MCC"
          onChange={handleChange}
        />
        <ContainerFlex row spacing gap="8">
          <ContainerFlex>
            <Select
              options={CAPTURE_FORMS}
              name="captureMethod"
              label="Forma de Captura"
              value={values?.captureMethod}
              onChange={(e) =>
                handleChange({ target: { name: 'captureMethod', value: e } })
              }
            />
          </ContainerFlex>
          <ContainerFlex>
            <Input
              mask={MaskTypes.NUMERIC}
              name="posQuantity"
              label="Quantidade POS"
              value={values?.posQuantity}
              onChange={handleChange}
            />
          </ContainerFlex>
        </ContainerFlex>
      </ContainerFlex>
      <ContainerFlex>
        <Input
          mask={MaskTypes.NUMERIC}
          name="monthlyCardTotalRevenue"
          label="Faturamento total mensal bandeirado"
          value={values?.monthlyCardTotalRevenue}
          onChange={handleChange}
        />
        <Select
          options={CONDITIONS}
          name="repassCondition"
          label="Condição de repasse"
          value={values?.repassCondition}
          onChange={(e) =>
            handleChange({ target: { name: 'repassCondition', value: e } })
          }
        />
      </ContainerFlex>
    </ContainerFlex>
  );
};
export default GeneralData;
