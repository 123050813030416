import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask, phoneMask } from '~/utils/masks';

export interface SalesObject {
  authorizerIdOrigin: string;
  companyName: string;
  companyCode: string;
  cardNumber: string;
  purchaseDate: string;
  purchaseTime: string;
  purchaseValue: number;
  installmentValue: number;
  purchasePlan: string;
  installments: string;
  dueDate: string;
  amountToReceive: number;
  repassCondition: string;
  receiptDate: string;
  receiptDateRule: string;
}
export interface RepassObject {
  companyName: string;
  companyCode: string;
  paymentId: any;
  bankCode: string;
  branch: string;
  account: string;
  digit: string;
  totalAmount: number;
  receiptDate: string;
}

export const HeaderRepass = [
  {
    label: 'Descrição EC',
    key: 'companyName',
  },
  {
    label: 'CNPJ EC',
    key: 'companyCode',
  },
  {
    label: 'ID Pagamento',

    key: 'paymentId',
  },
  {
    label: 'Banco',
    key: 'bankCode',
  },
  {
    label: 'Agencia',
    key: 'branch',
  },
  {
    label: 'Conta corrente',
    key: 'account',
  },
  {
    label: 'Digito',
    key: 'digit',
  },
  {
    label: 'Valor total líquido',
    key: 'totalAmount',
  },
  {
    label: 'Data de repasse',
    key: 'receiptDate',
  },
];

export const BodySales = (data: SalesObject[]) => {
  // let x = 0;

  const items = [];

  data?.forEach((i) => {
    items.push({
      ...i,
      purchaseValue: `R$ ${currencyMask(i.purchaseValue)}`,
      installmentValue: `R$ ${currencyMask(i.installmentValue)}`,
      amountToReceive: `R$ ${currencyMask(i.amountToReceive)}`,
    });
  });
  return items;
};
export const HeaderSales = [
  {
    label: 'Descrição EC',
    key: 'companyName',
  },
  {
    label: 'CNPJ',
    key: 'companyCode',
  },
  {
    label: 'Número cartão',

    key: 'cardNumber',
  },
  {
    label: 'Data venda',
    key: 'purchaseDate',
  },
  {
    label: 'Hora da venda',
    key: 'purchaseTime',
  },
  {
    label: 'Valor bruto total',
    key: 'purchaseValue',
  },
  {
    label: 'Valor bruto parcela',
    key: 'installmentValue',
  },
  {
    label: 'Plano venda',
    key: 'purchasePlan',
  },
  {
    label: 'Parcelas',
    key: 'installments',
  },
  {
    label: 'Data vencimento parcela',
    key: 'dueDate',
  },
  {
    label: 'Valor líquido',
    key: 'amountToReceive',
  },
  {
    label: 'Condição repasse',
    key: 'repassCondition',
  },
  {
    label: 'Data repasse',
    key: 'receiptDate',
  },
];

export const BodyRepass = (data: RepassObject[]) => {
  const items = [];

  data?.forEach((i) => {
    items.push({
      ...i,
      totalAmount: `R$ ${currencyMask(i.totalAmount)}`,
      receiptDate: moment(i.receiptDate).format('DD/MM/YYYY'),
    });
  });
  return items;
};
