/* eslint-disable no-param-reassign */
import axios from 'axios';
import BANKNOW_ENDPOINTS from './endpoints';

const banknowApi = axios.create({
  baseURL: process.env.REACT_APP_BANKNOW_API_URL,
});

const generateToken = async () => {
  let token = localStorage.getItem('banknow_bearer');
  const expires = localStorage.getItem('banknow_bearer_expires');

  if (token && expires) {
    const expirationDate = new Date(Number(expires));
    if (expirationDate > new Date()) {
      return token;
    }
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BANKNOW_API_URL}${BANKNOW_ENDPOINTS.USERS.postGenerateInternalToken}`,
      { method: 'POST' },
    );
    const data = await response.json();

    if (data.token && data.expirySeconds) {
      token = data.token;
      const expirationTime = new Date().getTime() + data.expirySeconds * 1000;

      localStorage.setItem('banknow_bearer', token);
      localStorage.setItem('banknow_bearer_expires', expirationTime.toString());
    }
    return token;
  } catch (error) {
    console.error('Error generating token:', error);
    return null;
  }
};

banknowApi.interceptors.request.use(async (config) => {
  const token = await generateToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  return config;
});

export default banknowApi;
