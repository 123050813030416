/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FaDownload } from 'react-icons/fa';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Loading from '~/components/loading';
import Pagination from '~/components/pagination';
import Select from '~/components/select';
import TableList from '~/components/tableList';
import { setFormState } from '~/utils/form';
import { UFs } from '../constants';
import { useCardReport } from './context/useCard';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';

import * as S from './styles';
import ResultNotFound from '~/components/resultNotFound';

const ReportProposal: React.FC = () => {
  const refCSV = useRef<any>(null);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [periodOff, setPeriodOff] = useState(false);

  const [form, setForm] = useState({
    tipoRelatorio: { name: 'tipoRelatorio', value: 'INATIVOS' },
    status: 'TODOS',
    tipoData: 'DATA_APROVACAO',
    periodoInicial: moment().format('DD/MM/YYYY'),
    periodoFinal: moment().format('DD/MM/YYYY'),
    origemPagamento: 'TODAS',
    statusFatura: 'TODOS',
    origem: 'TODOS',
    tipoPeriodo: 'TODOS',
    cartaoImpresso: 'TODOS',
    tipoAdesao: 'TODOS',
    uf: 'TODOS',
    operador: 'TODOS',
  });

  const {
    getReportInactiveCards,
    getReportUnlockedCards,
    getReportSalesPlatinumCard,
    getReportCardReissuance,
    getReportCardCSVDownload,
    header,
    headerItems,
    items,
    totalItems,
    totalPages,
    loading,
    REPORT_TYPES,
    clearForm,
    relatorioData,
    setRelatorioData,
    operators,
    getOperador,
  } = useCardReport();

  useEffect(() => {
    getOperador();
  }, []);

  const renderFilter = (invoiceType: string) => {
    switch (invoiceType) {
      case 'INATIVOS':
        return (
          <>
            <ContainerFlex row marginLeft={16} marginRight={16}>
              <ContainerFlex padding={16} marginRight={15}>
                <Select
                  label="Cartão impresso?"
                  value={form.cartaoImpresso}
                  options={[
                    { id: 'TODOS', value: 'Todos' },
                    { id: 'SIM', value: 'Sim' },
                    { id: 'NAO', value: 'Não' },
                  ]}
                  onChange={(e) => setForm({ ...form, cartaoImpresso: e })}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex marginLeft={16} marginRight={16}>
              <ContainerFlex padding={16} marginRight={15}>
                <Select
                  options={[
                    {
                      id: 'DATA_APROVACAO',
                      value: 'Data de Aprovação',
                    },
                    {
                      id: 'DATA_IMPRESSAO',
                      value: 'Data de Impressão',
                    },
                  ]}
                  label="Tipo de Período"
                  value={form.tipoData}
                  onChange={(e) => {
                    setForm({ ...form, tipoData: e });
                  }}
                />
              </ContainerFlex>
            </ContainerFlex>
          </>
        );
      case 'DESBLOQUEADOS':
        return (
          <ContainerFlex row space marginLeft={16} marginRight={16}>
            <ContainerFlex maxWidth={60} padding={16} marginRight={15}>
              <Select
                label="UF"
                value={form.uf}
                options={UFs}
                onChange={(e) => setForm({ ...form, uf: e })}
              />
            </ContainerFlex>
          </ContainerFlex>
        );
      case 'VENDAS_PLATINUM':
        return (
          <ContainerFlex row space marginLeft={16} marginRight={16}>
            <ContainerFlex maxWidth={60} padding={16} marginRight={15}>
              <Select
                label="Tipo de Adesão"
                value={form.tipoAdesao}
                options={[
                  { value: 'TODOS', id: 'TODOS' },
                  { value: 'ASSINATURA - Novo cliente', id: 'ASSINATURA' },
                  {
                    value: 'PROPOSTA - Upgrade CadProposta',
                    id: 'PROPOSTA',
                  },
                  { value: 'MANUAL - Upgrade Backoffice', id: 'MANUAL' },
                ]}
                onChange={(e) => setForm({ ...form, tipoAdesao: e })}
              />
            </ContainerFlex>
          </ContainerFlex>
        );
      default:
        return null;
    }
  };

  const payloadType = {
    INATIVOS: {
      origem: form.origem,
      idAtendente: form.operador,
      tipoPeriodo: form.tipoData,
      cartaoImpresso: form.cartaoImpresso,
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    },
    DESBLOQUEADOS: {
      idAtendente: form.operador,
      origem: form.origem,
      tipoPeriodo: form.tipoPeriodo,
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      uf: form.uf,
    },
    VENDAS_PLATINUM: {
      dataAdesaoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataAdesaoInicial:
        form.periodoFinal &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      operador: form.operador,
      tipoAdesao: form.tipoAdesao,
    },
    SEGUNDA_VIA: {
      fim:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      inicio:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    },
  };

  const getReport = async (page = 1) => {
    const payload = payloadType[form?.tipoRelatorio?.value];
    switch (form?.tipoRelatorio?.value) {
      case 'INATIVOS':
        await getReportInactiveCards(page, 100, false, payload);
        break;
      case 'DESBLOQUEADOS':
        getReportUnlockedCards(page, 100, false, payload);
        break;
      case 'VENDAS_PLATINUM':
        await getReportSalesPlatinumCard(page, 100, false, payload);
        break;
      case 'SEGUNDA_VIA':
        await getReportCardReissuance(page, 10, payload, false);
        break;
      default:
        break;
    }
  };

  const handleDownloadCSV = () => {
    const payload = {
      fim:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      inicio:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    getReportCardCSVDownload(payload);
  };

  useEffect(() => {
    clearForm();
  }, []);

  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;
    if (currentPage === 1) setDownloadCVSProgress('0%');
    const payload = payloadType[form?.tipoRelatorio?.value];

    switch (form?.tipoRelatorio?.value) {
      case 'INATIVOS':
        response = await getReportInactiveCards(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'DESBLOQUEADOS':
        response = await getReportUnlockedCards(
          currentPage,
          150,
          true,
          payload,
        );
        break;
      case 'VENDAS_PLATINUM':
        response = await getReportSalesPlatinumCard(
          currentPage,
          totalItems ?? 150,
          true,
          payload,
        );
        break;
      default:
        break;
    }

    if (response?.pagina?.length || response?.data) {
      const data = response.totalDePaginas ? response : response.data;

      totalPages = data.totalDePaginas || data.totalPaginas;

      const porcent = (currentPage / totalPages) * 100;
      const roundPorcent = Math.ceil(porcent);
      setDownloadCVSProgress(`${roundPorcent}%`);

      if (data?.pagina?.length || response?.data) {
        setRelatorioData((prev) => [...prev, ...(data?.pagina || data)]);
      }
      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }
    } else {
      setRelatorioData([]);
    }
  };

  return (
    <Container>
      <Loading status={loading} text={downloadCVSProgress} />
      <S.BoxCard>
        <ContainerFlex row space>
          <ContainerFlex maxWidth={17} padding={16}>
            <Select
              options={REPORT_TYPES}
              label="Tipo Relatório"
              name={form.tipoRelatorio.name}
              value={form.tipoRelatorio.value}
              onChange={(e) => {
                setFormState(
                  { target: { name: form.tipoRelatorio.name, value: e } },
                  form,
                  setForm,
                );
              }}
            />
          </ContainerFlex>

          {form.tipoRelatorio.value !== 'SEGUNDA_VIA' && (
            <ContainerFlex padding={16} marginRight={15}>
              <Select
                options={[
                  { id: 'TODOS', value: 'Todos os operadores' },
                  { id: '6041102c1b705c0007d62e81', value: 'Web' },
                  ...operators,
                ]}
                label="Operador(a) de Venda"
                value={form.operador}
                onChange={(e) => {
                  setForm({ ...form, operador: e });
                }}
              />
            </ContainerFlex>
          )}
          {form.tipoRelatorio.value !== 'VENDAS_PLATINUM' &&
            form.tipoRelatorio.value !== 'SEGUNDA_VIA' && (
              <ContainerFlex padding={16} maxWidth={15} marginRight={15}>
                <Select
                  label="Origem"
                  value={form.origem}
                  options={[
                    { id: 'TODOS', value: 'Todas as origens' },
                    { id: 'LOJA', value: 'Loja' },
                    { id: 'SITE', value: 'Site' },
                    { id: 'APP', value: 'App' },
                    { id: 'TOTEM', value: 'Totem' },
                  ]}
                  onChange={(e) => setForm({ ...form, origem: e })}
                />
              </ContainerFlex>
            )}

          {renderFilter(form.tipoRelatorio.value)}

          <ContainerFlex flex="0.2" padding={16}>
            <Button
              onClick={() => {
                if (form.periodoInicial && form.periodoFinal) {
                  setPeriodOff(false);
                  getReport();
                } else {
                  setPeriodOff(true);
                }
              }}
            >
              <span>Consultar</span>
            </Button>
          </ContainerFlex>
        </ContainerFlex>
        {periodOff ? (
          <S.SpanLabelFeed>
            * Insira um período de busca válido.
          </S.SpanLabelFeed>
        ) : null}
        <ContainerFlex marginLeft={16}>
          <S.SpanLabel>Período</S.SpanLabel>
        </ContainerFlex>

        <ContainerFlex marginLeft={16} maxWidth={50} marginRight={15} row>
          {form.tipoRelatorio.value === 'DESBLOQUEADOS' && (
            <ContainerFlex marginRight={15}>
              <Select
                label="Tipo de período"
                value={form.tipoPeriodo}
                options={[
                  { id: 'TODOS', value: 'Todos os períodos' },
                  {
                    id: 'APROVACAO_PROPOSTA',
                    value: 'Período de Aprovação de Proposta',
                  },
                ]}
                onChange={(e) => setForm({ ...form, tipoPeriodo: e })}
              />
            </ContainerFlex>
          )}

          <ContainerFlex maxWidth={20} marginRight={15} row>
            <Input
              label="De"
              placeholder="DD/MM/YYYY"
              maxLength={10}
              value={form?.periodoInicial}
              onChange={(e) => {
                return setForm({
                  ...form,
                  periodoInicial: e.target.value.masked,
                });
              }}
              mask={MaskTypes.DATE}
            />
          </ContainerFlex>
          <ContainerFlex maxWidth={20}>
            <Input
              label="Até"
              placeholder="DD/MM/YYYY"
              maxLength={10}
              value={form?.periodoFinal}
              onChange={(e) => {
                return setForm({
                  ...form,
                  periodoFinal: e.target.value.masked,
                });
              }}
              mask={MaskTypes.DATE}
            />
          </ContainerFlex>
        </ContainerFlex>
      </S.BoxCard>

      <CSVLink
        ref={refCSV}
        data={relatorioData}
        style={{
          color: 'inherit',
          textDecoration: 'none',
        }}
        headers={header}
        filename={`relatorio_cartões_${form.tipoRelatorio.value}.csv`}
        target="_blank"
      />

      {items?.length > 0 ? (
        <S.BoxCard>
          <ContainerFlex flex="0.2" padding={16} marginTop={10}>
            <ContainerFlex row space center padding={24}>
              <ContainerFlex>
                <p>Resultados retornados: {totalItems}</p>
              </ContainerFlex>
              {form.tipoRelatorio.value === 'SEGUNDA_VIA' ? (
                <Button
                  onClick={() => {
                    handleDownloadCSV();
                  }}
                >
                  <FaDownload />
                  <span style={{ marginLeft: '8px' }}>Download Relatório</span>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setRelatorioData([]);
                    if (refCSV.current) {
                      onClickDownloadCSV().then(() =>
                        refCSV.current.link.click(),
                      );
                    }
                  }}
                >
                  Download relatório
                </Button>
              )}
            </ContainerFlex>
            <ContainerFlex>
              <TableList customerHeader={headerItems} list={items} />
              <ContainerFlex row horizontal center marginTop={20}>
                <Pagination
                  totalPages={totalPages}
                  onCurrentPageChange={getReport}
                />
              </ContainerFlex>
            </ContainerFlex>
          </ContainerFlex>
        </S.BoxCard>
      ) : (
        <ResultNotFound />
      )}
    </Container>
  );
};

export default ReportProposal;
