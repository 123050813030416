import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FaDownload } from 'react-icons/fa';
import Button from '~/components/button';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Loading from '~/components/loading';

import Select from '~/components/select';
import * as S from './styles';

import Input, { MaskTypes } from '~/components/input';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';
import banknowApi from '~/services/banknow/api';
import BANKNOW_ENDPOINTS from '~/services/banknow/endpoints';
import { cnpjMask, cpfMask } from '~/utils/masks';
import { BodyRepass, BodySales, HeaderRepass, HeaderSales } from './models';
import { icons } from '~/assets';
import Dialog from '~/components/dialog';

const reportOptions = [
  { value: 'Vendas por data de compra', id: 'DATA_COMPRA' },
  { value: 'Vendas por data de repasse', id: 'DATA_REPASSE' },
  { value: 'Repasse de vendas', id: 'REPASSE' },
];

export default function TeuPagReport() {
  const refCSV = useRef<any>(null);
  const [form, setForm] = useState({
    periodoInicial: moment().format('DD/MM/YYYY'),
    periodoFinal: moment().format('DD/MM/YYYY'),
    tipoRelatorio: 'DATA_COMPRA',
    establishment: 'TODOS',
  });
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');

  const [allEstablishment, setAllEstabilshment] = useState([]);
  const getSearchEstablishments = async (params?) => {
    const p = new URLSearchParams({
      limit: 1000,
      page: 1,
      ...params,
    });
    const response = await banknowApi.get(
      BANKNOW_ENDPOINTS.COMPANIES.getCompanies(p),
    );
    if (response.status === 200) {
      const { data, page, totalPages } = response.data;
      setAllEstabilshment(data);
      return data;
    }
  };
  useEffect(() => {
    getSearchEstablishments();
  }, []);

  const getReport = async () => {
    setLoading(true);

    try {
      let endpoint = '';
      const dates = `${form.periodoInicial
        .split('/')
        .reverse()
        .join('-')},${form.periodoFinal.split('/').reverse().join('-')}`;

      const params = new URLSearchParams({
        dates,
      });
      let company = '';
      if (form.establishment === 'TODOS') {
        company = 'ALL';
        params.append('brand', 'TEUCARD');
      } else {
        company = form.establishment;
      }
      if (['DATA_COMPRA', 'DATA_REPASSE'].includes(form.tipoRelatorio)) {
        params.append(
          'type',
          form.tipoRelatorio === 'DATA_COMPRA' ? 'purchase' : 'receipt',
        );
        endpoint = BANKNOW_ENDPOINTS.REPORT.listSalesByDate(company, params);

        setHeaders(HeaderSales);
      }
      if (form.tipoRelatorio === 'REPASSE') {
        endpoint = BANKNOW_ENDPOINTS.REPORT.listSalesByPayoutDate(
          company,
          params,
        );
        setHeaders(HeaderRepass);
      }

      console.log(endpoint);
      const response = await banknowApi.get(endpoint);

      if (response.status >= 200) {
        if (['DATA_COMPRA', 'DATA_REPASSE'].includes(form.tipoRelatorio)) {
          if (!response.data?.salesDataResponseList?.length) {
            setOpenDialog(true);
            setMessage('Nenhum arquivo encontrado!');
            setLoading(false);

            return [];
          }
          setData(BodySales(response.data?.salesDataResponseList));
          setLoading(false);

          return response?.data?.salesDataResponseList;
        }
        if (form.tipoRelatorio === 'REPASSE') {
          if (!response.data?.salesPayoutResponses?.length) {
            setOpenDialog(true);
            setMessage('Nenhum arquivo encontrado!');
            setLoading(false);

            return [];
          }
          setData(BodyRepass(response?.data?.salesPayoutResponses));
          setLoading(false);

          return response?.data?.salesPayoutResponses;
        }
      }
    } catch (error) {
      setLoading(false);
      setOpenDialog(true);
      setMessage(error?.response?.data?.message ?? 'Houve um erro');
    }
  };

  return (
    <>
      <Loading status={loading} />
      <Dialog
        open={openDialog}
        icon={icons.exclamation}
        positiveAction={() => {
          setOpenDialog(false);
        }}
        message={message}
        positiveLabel="Entendi"
      />
      <Container>
        <S.BoxCard>
          <ContainerFlex row space gap="16">
            <ContainerFlex>
              <Select
                options={reportOptions}
                label="Tipo"
                name="tipoRelatorio"
                value={form.tipoRelatorio}
                onChange={(e) => {
                  setForm((prev) => ({ ...prev, tipoRelatorio: e }));
                }}
              />
            </ContainerFlex>
            <ContainerFlex marginRight={16}>
              <Input
                label="De"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form.periodoInicial}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    periodoInicial: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>

            <ContainerFlex marginRight={16}>
              <Input
                label="Até"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={form.periodoFinal}
                onChange={(e) => {
                  return setForm({
                    ...form,
                    periodoFinal: e.target.value.masked,
                  });
                }}
                mask={MaskTypes.DATE}
              />
            </ContainerFlex>
            <ContainerFlex>
              <AutoCompleteSelect
                options={[
                  { value: 'TODOS', id: 'TODOS', key: 'all' },
                  ...allEstablishment.map((e) => ({
                    value: `${e.name} - ${
                      e.document.length > 11
                        ? cnpjMask(e.document).masked
                        : cpfMask(e.document).masked
                    }`,
                    id: e.id,
                    key: e.id,
                  })),
                ]}
                label="Estabelecimento"
                getOptionKey={(op) => op?.key}
                filterFunction={(options, filterText) =>
                  options.filter((op) =>
                    op.value
                      ?.toLowerCase()
                      ?.trim()
                      .includes(filterText?.toLowerCase()?.trim()),
                  )
                }
                value={form.establishment}
                onChange={(e) => {
                  setForm({
                    ...form,
                    establishment: e.id,
                  });
                }}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Button
                onClick={async () => {
                  const data = await getReport();
                  if (data?.length) {
                    refCSV.current.link.click();
                  }
                }}
                disabled={loading}
              >
                <FaDownload />
                <span style={{ marginLeft: 8 }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>

          <CSVLink
            ref={refCSV}
            data={data}
            style={{
              color: 'inherit',
              textDecoration: 'none',
            }}
            headers={headers}
            filename={`relatorio_${form.tipoRelatorio}_de_${form.periodoInicial}_ate_${form.periodoFinal}.csv`}
            target="_blank"
          />
        </S.BoxCard>
      </Container>
    </>
  );
}
