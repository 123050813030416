/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '~/components/container';
import * as St from './styles';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/newInput';
import Button from '~/components/button';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import {
  cepMask,
  cnpjMask,
  cpfCnpjMask,
  cpfMask,
  IDefaultReturn,
  phoneMask,
} from '~/utils/masks';
import { IListCol } from '~/components/listView/row/col';
import TableList from '~/components/tableList';
import Pagination from '~/components/pagination';
import Loading from '~/components/loading';
import { IListColumn } from '~/models/listCol';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';
import Collapse from '~/components/collapse';
import ParametersData from './data';
import banknowApi from '~/services/banknow/api';
import BANKNOW_ENDPOINTS from '~/services/banknow/endpoints';
import { CompanyDataResponse } from '~/services/banknow/types';

interface Address {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  logradouro: string;
  numero: string;
  pais: string;
  uf: string;
}

interface IEstablishment {
  cpf: string;
  cnpj: string;
  email: string;
  endereco: Address;
  id: string;
  telefone: string;
}

interface IEstablishmentResponse {
  currentPage: number;
  data: IEstablishment[];
  pageSize: number;
  totalItens: number;
  totalPages;
}

interface IForm {
  name: string;
  cpfCnpj: IDefaultReturn;
  estabilishment: string;
}

const TeuPagConsult = () => {
  const [form, setForm] = useState<IForm>({} as IForm);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allEstablishment, setAllEstabilshment] = useState([]);

  const RequestSearchEstablishments = useApi<IEstablishmentResponse>(
    `${Endpoints.establishments.getSeacrhEstablishments}?page=1&size=100`,
    ApiMethod.GET,
  );

  const getSearchEstablishments = async (params?) => {
    const p = new URLSearchParams({
      limit: 1000,
      page: currentPage,
      ...params,
    });
    const response = await banknowApi.get(
      BANKNOW_ENDPOINTS.COMPANIES.getCompanies(p),
    );
    if (response.status === 200) {
      const { data, page, totalPages }: IEstablishmentResponse = response.data;
      setAllEstabilshment(data);
      setCurrentPage(page);
      setTotalPages(totalPages);
      return data;
    }
  };
  useEffect(() => {
    getSearchEstablishments();
  }, []);

  const [parameters, setParameters] = useState(null);
  const getDetailsEstablishments = async () => {
    setParameters(null);
    try {
      const response = await banknowApi.get<CompanyDataResponse>(
        BANKNOW_ENDPOINTS.COMPANIES.getCompanyDetails(form.estabilishment),
      );
      const { data } = response;

      const bank = data.homeAccount;
      if (
        !data.generalParameters ||
        !data.splitsModalities ||
        !data.homeAccount
      ) {
        setOpenDialog(true);
        setMessage(
          `Estabelecimento consultado pertence ao grupo ${data.businessGroups[0]?.name} .`,
        );
        return;
      }
      const parametersObject = {
        id: data.id,
        generalParametersData: {
          mcc: data.generalParameters.mcc,
          captureMethod: data.generalParameters.captureMethod,
          posQuantity: data.generalParameters.posQuantity,
          monthlyCardTotalRevenue:
            data.generalParameters.monthlyCardTotalRevenue,
          repassCondition: data.generalParameters.repassCondition,
        },
        splitsModalities: data.splitsModalities.map((split) => ({
          modality: split.splitPayment,
          percentualSplit: split.percentual,
        })),
        bankData: {
          accountHolder: {
            holderName: bank.accountHolder.holderName,
            cpfCnpj:
              bank.accountHolder.cpfCnpj.length > 11
                ? cnpjMask(bank.accountHolder.cpfCnpj)
                : cpfMask(bank.accountHolder.cpfCnpj),
          },
          bankName: bank.bankFullName,
          agency: bank.branch,
          account: bank.account,
          digit: bank.digit,
          bank: bank.bankIspb,
          code: bank.bankCode,
          type: bank.accountType.replace('_', ''),
        },
      };

      if (response.status === 200) {
        setParameters(parametersObject);
      }
    } catch (error) {
      console.log(error);
      setOpenDialog(true);
      setMessage(error?.response?.data?.message ?? 'Houve um erro');
    }
  };

  return (
    <>
      <Loading status={RequestSearchEstablishments.loading} />
      <Dialog
        open={openDialog}
        icon={icons.exclamation}
        positiveAction={() => {
          setOpenDialog(false);
        }}
        message={message}
        positiveLabel="Entendi"
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row>
            <ContainerFlex flex={1} marginRight={16}>
              <AutoCompleteSelect
                options={allEstablishment.map((e) => ({
                  value: `${e.name} - ${
                    e.document.length > 11
                      ? cnpjMask(e.document).masked
                      : cpfMask(e.document).masked
                  }`,
                  id: e.document,
                  key: e.id,
                }))}
                label="Estabelecimento"
                getOptionKey={(op) => op?.key}
                filterFunction={(options, filterText) =>
                  options.filter((op) =>
                    op.value
                      ?.toLowerCase()
                      ?.trim()
                      .includes(filterText?.toLowerCase()?.trim()),
                  )
                }
                value={form.name}
                onChange={(e) => {
                  setForm({
                    ...form,
                    estabilishment: e.id,
                  });
                }}
              />
            </ContainerFlex>

            <ContainerFlex end row gap="30">
              <Button
                fitContent
                onClick={() => getDetailsEstablishments()}
                disabled={!form.estabilishment}
              >
                Consultar
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>

        {parameters ? <ParametersData parameters={parameters} /> : null}
      </Container>
    </>
  );
};

export default TeuPagConsult;
