import { IDefaultReturn } from '~/utils/masks';

export const BIOMETRICS_ORIGIN = [
  {
    id: 'TODAS',
    name: 'Todas',
    value: 'Todas',
  },
  {
    id: 'T_SENHA',
    name: 'Alteração de senha',
    value: 'Alteração de senha',
  },
  {
    id: 'T_EMPRESTIMO',
    name: 'Empréstimo Hotmoney',
    value: 'Empréstimo Hotmoney',
  },
  {
    id: 'T_CHANGE_PERSONAL_DATA',
    name: 'Alteração de dados',
    value: 'Alteração de dados',
  },
  { id: 'T_DEVICE_ID', name: 'Device ID', value: 'Device ID' },
  { id: 'PROPOSTA', name: 'Proposta', value: 'Proposta' },
  {
    id: 'T_ADD_CARD_WALLET',
    name: 'Adicionar cartão na carteira',
    value: 'Adicionar cartão na carteira',
  },
];

export const RESULT_LIVENESS = [
  {
    id: 'TODOS',
    name: 'Todos',
    value: 'Todos',
  },
  {
    id: '1.0',
    name: '1.0 - Vida aprovada',
    value: '1.0 - Vida aprovada',
  },
  {
    id: '300.1',
    name: '300.1 - Vida reprovada retentativa',
    value: '300.1 - Vida reprovada retentativa',
  },
  {
    id: '300.2',
    name: '300.2 - Vida reprovada bloqueado',
    value: '300.2 - Vida reprovada bloqueado',
  },
];

export const BASE_RESULT_FACES = [
  {
    id: 'TODOS',
    name: 'Todos',
    value: 'Todos',
  },
  {
    id: '201',
    name: '201 - Cadastro com sucesso',
    value: '201 - Cadastro com sucesso',
  },
  {
    id: '203',
    name: '203 - Certificacao positiva',
    value: '203 - Certificacao positiva',
  },
  {
    id: '204',
    name: '204 - Certificacao negativa',
    value: '204 - Certificacao negativa',
  },
  {
    id: '401',
    name: '401 - Falha na qualidade',
    value: '401 - Falha na qualidade',
  },
  {
    id: '501',
    name: '501 - Falha interna',
    value: '501 - Falha interna',
  },
];

export interface FormState {
  cpf: IDefaultReturn;
  origem: string;
  resultLiveness: string;
  baseResultFace: string;
  dataBiometriaInicial: string;
  dataBiometriaFinal: string;
}
