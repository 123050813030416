import moment from 'moment';
import { IListColumn } from '~/models/listCol';
import { cpfMask, currencyMask } from '~/utils/masks';

export const HeaderRecipeAgreement = [
  {
    id: 1,
    value: 'CPF',
    textAlign: 'center',
    maxChar: 14,
  },
  {
    id: 2,
    value: 'Nome completo',
    maxChar: 20,
  },
  {
    id: 3,
    value: 'Valor da Dívida',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 4,
    value: 'Valor Pago',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 5,
    value: 'Valor Líquido',
    textAlign: 'center',
    maxChar: 12,
  },
  {
    id: 6,
    value: 'Resultado',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 7,
    value: 'Data Inclusão',
    textAlign: 'center',
    maxChar: 15,
  },
  {
    id: 8,
    value: 'Data Decisão',
    textAlign: 'center',
    maxChar: 15,
  },
];

export const BodyRecipeAgreement = (data: any) => {
  // let x = 0;

  const items = [];

  data.pagina?.forEach((i) => {
    const item: IListColumn[] = [
      // {
      //   type: 'id',
      //   value: x++,
      //   maxChar: 5,
      // },
      {
        value: i.cpf ? cpfMask(i.cpf).masked : '',
        textAlign: 'center',
        maxChar: 14,
      },
      {
        value: i.nome,
        maxChar: 20,
      },
      {
        value: `R$ ${currencyMask(i.valorDivida)}`,
        textAlign: 'center',
        maxChar: 15,
      },
      {
        value: `R$ ${currencyMask(i.valorPago)}`,
        textAlign: 'center',
        maxChar: 15,
      },
      {
        value: `R$ ${currencyMask(i.valorLiquido).replace(".", "")}`,
        textAlign: 'center',
        maxChar: 12,
      },
      {
        value: `R$ ${currencyMask(i.resultado).replace(".", "")}`,
        textAlign: 'center',
        maxChar: 15,
      },
      {
        value: i.dataInclusao
          ? moment(i.dataInclusao).format('DD/MM/YYYY')
          : '',
        textAlign: 'center',
        maxChar: 15,
      },
      {
        value: i.dataDecisao ? moment(i.dataDecisao).format('DD/MM/YYYY') : '',
        textAlign: 'center',
        maxChar: 15,
      },
    ];
    items.push(item);
  });
  return items;
};
