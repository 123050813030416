/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, useCallback, useEffect, useState } from 'react';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import {
  GetEstablishmentsResponse,
  GetOperatorsResponse,
} from '~/services/report/requestReport/response';

import { CSV_HEADERS } from '../../constants';
import Models from '../../models';
import {
  REPORT_TYPES,
  IAccessionSaleReport,
  ReportResponseAdesoes,
  ReportResponseVendas,
  ReportResponseTransactions,
} from '../models';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';

export const AccessionSaleReportContext = createContext<IAccessionSaleReport>(
  {} as IAccessionSaleReport,
);

const AccessionSaleReportProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [relatorioData, setRelatorioData] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [request, setRequest] = useState(false);
  const [operador, setOperador] = useState([]);
  const [establishment, setEstablishment] = useState([]);
  const [errorDialogIsOpen, setErrorDialogIsOpen] = useState(false);
  const [dialogInformativetText, setInformativetText] = useState('');

  const RequestGetOperators = useApi(
    Endpoints.report.getOperators,
    ApiMethod.GET,
  );

  const GetCompanies = useApi(Endpoints.consult.searchCompanyByAutorizador, ApiMethod.GET);

  const RequestReportAccession = useApi<ReportResponseAdesoes>(
    Endpoints.report.getReportAdesoes,
    ApiMethod.POST,
  );

  const RequestReportVendas = useApi<ReportResponseVendas>(
    Endpoints.report.getReportVendas,
    ApiMethod.POST,
  );

  const RequestReportTransactions = useApi<ReportResponseTransactions>(
    Endpoints.report.getReportWalletTransactions,
    ApiMethod.POST,
  );

  const clearForm = () => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
  };

  useEffect(() => {
    if (
      RequestGetOperators.loading ||
      GetCompanies.loading ||
      RequestReportAccession.loading ||
      RequestReportVendas.loading ||
      RequestReportTransactions.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    RequestGetOperators.loading,
    GetCompanies.loading,
    RequestReportAccession.loading,
    RequestReportVendas.loading,
    RequestReportTransactions.loading,
  ]);

  const checkSuccess = () => {
    if (
      RequestGetOperators.error ||
      RequestGetOperators.status === 404 ||
      RequestGetOperators.status === 500 ||
      GetCompanies.error ||
      GetCompanies.status === 404 ||
      GetCompanies.status === 500 ||
      RequestReportAccession.error ||
      RequestReportAccession.status === 404 ||
      RequestReportAccession.status === 500 ||
      RequestReportVendas.error ||
      RequestReportVendas.status === 404 ||
      RequestReportVendas.status === 500 ||
      RequestReportTransactions.error ||
      RequestReportTransactions.status === 404 ||
      RequestReportTransactions.status === 500 ||
      !request
    ) {
      return false;
    }

    return true;
  };

  const postReportAccession = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    setRequest(false);
    const newPayload = {
      ...payload,
      qtdRegistros: limit,
      pagina: page,
    };

    const response = await RequestReportAccession.callApi(newPayload);

    setRequest(true);
    setHeader(CSV_HEADERS.CARTEIRA_DIGITAL_ADESAO);

    if (returnResponse) return response.data;

    const { data = '' } = (response.data as ReportResponseAdesoes) || {};

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportAdesoes);
      setItems(data ? Models.BodyReportAdesoes(data) : []);
      setTotalItems(response.data.totalItens);
      setTotalPages(response.data.totalPaginas);
    } else {
      setInformativetText('Ops! Internal Server Error.');
      setErrorDialogIsOpen(true);
    }
  };

  const postReportSale = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    setRequest(false);
    const newPayload = {
      ...payload,
      qtdRegistros: limit,
      pagina: page,
    };

    const response = await RequestReportVendas.callApi(newPayload);

    setRequest(true);
    setHeader(CSV_HEADERS.CARTEIRA_DIGITAL_VENDA);

    if (returnResponse) return response.data;

    const { data = '' } = (response.data as ReportResponseVendas) || {};

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportVendas);
      setItems(data ? Models.BodyReportVendas(data) : []);
      setTotalItems(response.data.totalItens);
      setTotalPages(response.data.totalPaginas);
      setRelatorioData(Models.BodyReportVendasCSV(data));
    } else {
      setInformativetText('Ops! Internal Server Error.');
      setErrorDialogIsOpen(true);
    }
  };

  const postReportTransactions = async (
    page = 1,
    limit = 10,
    returnResponse = false,
    payload,
  ) => {
    setRequest(false);
    const newPayload = {
      ...payload,
      qtdRegistros: limit,
      pagina: page,
    };

    const response = await RequestReportTransactions.callApi(newPayload);

    setRequest(true);
    setHeader(CSV_HEADERS.CARTEIRA_DIGITAL_TRANSACAO);

    if (returnResponse) return response.data;

    const { data = '' } = (response.data as ReportResponseTransactions) || {};

    if (response.status === 200) {
      setHeaderItems(Models.HeaderReportWalletTransactions);
      setItems(data ? Models.BodyReportWalletTransactions(data) : []);
      setTotalItems(response.data.totalItens);
      setTotalPages(response.data.totalPaginas);
      setRelatorioData(Models.BodyReportWalletTransactionsCSV(data));
    } else {
      setInformativetText('Ops! Internal Server Error.');
      setErrorDialogIsOpen(true);
    }
  };

  const getOperators = useCallback(async () => {
    const { data, status, mensagem } = await RequestGetOperators.callApi();

    return { data, status, mensagem };
  }, [RequestGetOperators]);

  const getOperador = async () => {
    const response = await getOperators();

    if (response.status === 200) {
      const data = response.data as GetOperatorsResponse[];

      const getOperadores = data.map(({ nome, id }) => ({
        id,
        value: nome,
      }));

      setOperador(getOperadores);
    }
  };

  const getEstablishments = useCallback(async () => {
    const { data, status, mensagem } = await GetCompanies.callApi();

    return { data, status, mensagem };
  }, [GetCompanies]);

  const getEstablishment = async () => {
    const response = await getEstablishments();

    if (response.status === 200) {
      const data = response.data as GetEstablishmentsResponse[];

      const companies = data.map(({ nome, cnpj }) => ({
        id: cnpj,
        value: nome,
      }));

      setEstablishment(companies);
    }
  };

  return (
    <>
      <Dialog
        icon={icons.warning}
        open={errorDialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setErrorDialogIsOpen(false)}
        message={dialogInformativetText}
      />
      <AccessionSaleReportContext.Provider
        value={{
          postReportAccession,
          postReportSale,
          postReportTransactions,
          getOperador,
          getEstablishment,
          checkSuccess,
          loading,
          items,
          headerItems,
          header,
          totalItems,
          totalPages,
          relatorioData,
          setRelatorioData,
          clearForm,
          operador,
          establishment,
          REPORT_TYPES,
          request,
        }}
      >
        {children}
      </AccessionSaleReportContext.Provider>
    </>
  );
};
export default AccessionSaleReportProvider;
