/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { icons } from '~/assets';
import Button from '~/components/button';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Grid from '~/components/grid';
import Loading from '~/components/loading';
import useApi, { ApiMethod } from '~/hooks/useApi';
import banknowApi from '~/services/banknow/api';
import BANKNOW_ENDPOINTS from '~/services/banknow/endpoints';
import Endpoints from '~/services/endpoints';
import Input, { MaskTypes } from '~/components/newInput';
import Select from '~/components/select';
import BankData from '~/views/Teupag/parameters/components/BankData';
import GeneralData from '~/views/Teupag/parameters/components/GeneralData';
import MDRData from '~/views/Teupag/parameters/components/MDRData';
import { CompanyDataBodyRequest } from '~/services/banknow/types';
import { CompanyDetailForm } from '..';

const CPFEstabilishment = ({
  form,
  setForm,
}: {
  form: CompanyDataBodyRequest;
  setForm: React.Dispatch<React.SetStateAction<CompanyDataBodyRequest>>;
}) => {
  const [dialogError, setDialogError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [dialogGoBack, setDialogGoBack] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [groups, setGroups] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  console.log('CNPJ FORM', form);

  const { state } = useLocation<{ document?: string; id?: string }>();

  const isEditing = !!state?.id || !!state?.document;
  const updateNestedValue = (obj, path, value) => {
    if (!path.length) return value;
    const [key, ...rest] = path;
    return { ...obj, [key]: updateNestedValue(obj[key] || {}, rest, value) };
  };
  const handleChange = ({ target: { value, name } }) => {
    // setForm((prev) => ({ ...prev, [name]: value }));
    setForm((prev) => updateNestedValue(prev, name.split('.'), value));
  };

  const getBusinesscGroups = async () => {
    const response = await banknowApi.post(
      BANKNOW_ENDPOINTS.COMPANIES.getBusinessGroups,
      { active: true, page: 1, limit: 100 },
    );
    setGroups(response.data?.data?.map((e) => ({ id: e.id, value: e?.name })));
  };
  useEffect(() => {
    getBusinesscGroups();
  }, []);

  const RequestCreateEstablishment = useApi(
    Endpoints.establishments.postCreateEstablishment,
    ApiMethod.POST,
  );

  const RequestEditEstablishment = useApi(
    Endpoints.establishments.putEditEstablishment.replace(
      'PARAM_ID',
      state?.id,
    ),
    ApiMethod.PUT,
  );

  const isButtonDisabled = () => {
    const isCNPJ = form?.cnpj?.masked?.length === 18;
    const representanteLegalFilled = form?.responsible?.name;

    return !(isCNPJ && representanteLegalFilled);
  };

  const getNestedValue = (obj, path) => {
    return path.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj,
    );
  };

  const handleErrors = () => {
    const fieldsToCheck = [
      'companyData.addressNumber',
      'companyData.addressState',
      'companyData.addressDistrict',
      'companyData.addressCity',
      'companyData.addressZipCode.masked',
      'companyData.addressStreet',
      'companyData.cnpj.masked',
      'companyData.email',
      'companyData.phone',
      'companyData.tradeName',
      'companyData.fantasyName',
      // 'responsiblesData.name',
      // 'responsiblesData.cpf.masked',
      'responsiblesData.dateOfBirth.masked',
      // 'responsiblesData.occupation',
      // 'responsiblesData.email',
      // 'responsiblesData.phone.masked',
    ];
    if (form?.companyData?.businessGroupInfoData?.isCompanyBusinessGroup) {
      fieldsToCheck.push(
        'companyData.businessGroupInfoData.businessGroupData.name',
      );
    } else {
      fieldsToCheck.push(
        'companyData.businessGroupInfoData.businessGroupData.id',
      );
    }
    const errors = {};

    fieldsToCheck.forEach((field) => {
      const value = getNestedValue(form, field.split('.'));
      if (value === undefined || value === null || value === '') {
        errors[field] = 'Campo obrigatório';
      }
    });

    if (form.companyData?.phone?.unmasked?.length < 10) {
      errors['companyData.phone.masked'] = 'Celular inválido';
    }
    if (form.companyData?.cnpj?.unmasked?.length < 11) {
      errors['companyData.cnpj.masked'] = 'CPF inválido';
    }
    if (
      !moment(
        form?.responsiblesData?.dateOfBirth?.masked,
        'DD/MM/YYYY',
      ).isValid()
    ) {
      errors['responsiblesData.dateOfBirth.masked'] = 'Data inválida';
    }
    setErrors(errors);

    console.log(errors);
    return Object.keys(errors).length > 0;
  };
  console.log('CPF FORM', form);
  const handleUpdateCompany = async (payload) => {
    const response = await banknowApi.put(
      BANKNOW_ENDPOINTS.COMPANIES.updateCompanyInterface(state?.id),
      payload,
    );
    return response;
  };
  const handleCreateCompany = async (payload) => {
    const response = await banknowApi.post(
      BANKNOW_ENDPOINTS.COMPANIES.createCompanyInterface,
      payload,
    );
    return response;
  };
  console.log('STATE', state);
  const handleSubmit = async () => {
    if (handleErrors()) return;
    const { companyData, bankData, responsiblesData } = form;

    const payload: CompanyDataBodyRequest = {
      companyData: {
        // endereço
        ...companyData,
        addressZipCode: companyData?.addressZipCode?.unmasked,
        addressCountry: 'Brasil',

        phone: companyData?.phone?.unmasked,
        // category: form?.category,
        cnpj: companyData?.cnpj?.unmasked,
        category: 'OTHER',
        brand: 'TEUCARD',
      },
      responsiblesData: [
        {
          name: companyData?.tradeName,
          email: companyData?.email,
          occupation: 'DONO',
          cpf: companyData?.cnpj?.unmasked,
          phone: companyData?.phone?.unmasked,
          dateOfBirth: moment(
            responsiblesData?.dateOfBirth?.masked,
            'DD/MM/YYYY',
          ).format('YYYY-MM-DD'),
          role: companyData?.businessGroupInfoData?.isCompanyBusinessGroup
            ? 'PARTNER_MASTER'
            : 'PARTNER',
        },
      ],
      ...(form?.bankData?.account &&
        !state?.document && {
          bankData: [
            {
              ...bankData,

              type: 'CHECKING',
              accountHolder: {
                ...bankData?.accountHolder,
                cpfCnpj: bankData?.accountHolder?.cpfCnpj?.unmasked,
              },
            },
          ],
        }),
    };
    console.log(payload);
    try {
      setLoading(true);
      const response = state?.document
        ? await handleUpdateCompany(payload)
        : await handleCreateCompany(payload);

      if (response.status >= 200) {
        setDialogError(true);
        setMessageError(
          state?.document
            ? 'Cliente editado com sucesso!'
            : 'Cliente criado com sucesso!',
        );
        setDialogGoBack(true);
      } else if (response.status >= 404) {
        setDialogError(true);
        setMessageError(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      setDialogError(true);
      setMessageError(error?.response?.data?.message ?? 'Houve um erro');
      console.log(error?.message);
      setLoading(false);
    }
  };

  const handleResendWelcomeEmail = async () => {
    try {
      setLoading(true);
      const response = await banknowApi.post(BANKNOW_ENDPOINTS.EMPLOYEES.sendNewPasswordByDocument(form?.responsiblesData?.cpf.unmasked));

      if (response.status >= 200) {
        setDialogError(true);
        setMessageError("Email de boas-vindas enviado!");
        setDialogGoBack(true);
      } else if (response.status >= 404) {
        setDialogError(true);
        setMessageError(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      setDialogError(true);
      setMessageError(error?.response?.data?.message ?? 'Houve um erro');
      console.log(error?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Loading
        status={
          RequestCreateEstablishment.loading ||
          RequestEditEstablishment.loading ||
          loading
        }
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
          dialogGoBack && history.push('/teu-pag/estabelecimento');
        }}
        message={messageError}
      />
      <ContainerFlex>
        <Grid xs={1} sm={2} lg={2}>
          <ContainerFlex>
            <ContainerFlex>
              <Input
                label="CPF"
                name="companyData.cnpj"
                placeholder="000.000.000-00"
                maxLength={14}
                disabled={isEditing}
                value={form?.companyData?.cnpj?.masked}
                onChange={handleChange}
                mask={MaskTypes.CPF}
                errorMessage={errors['companyData.cnpj.masked']}
                autoComplete="off"
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                disabled={isEditing}
                label="Nome completo"
                name="companyData.tradeName"
                value={form?.companyData?.tradeName}
                onChange={handleChange}
                errorMessage={errors['companyData.tradeName']}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Data de nascimento"
                name="responsiblesData.dateOfBirth"
                value={form?.responsiblesData?.dateOfBirth?.masked}
                onChange={handleChange}
                maxLength={10}
                mask={MaskTypes.DATE}
                errorMessage={errors['responsiblesData.dateOfBirth.masked']}
              />
            </ContainerFlex>

            <ContainerFlex>
              <Input
                label="Telefone"
                name="companyData.phone"
                value={form?.companyData?.phone?.masked}
                placeholder="(00) 00000-0000"
                maxLength={15}
                onChange={handleChange}
                mask={MaskTypes.PHONE}
                errorMessage={errors['companyData.phone.masked']}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="E-mail "
                name="companyData.email"
                value={form?.companyData?.email}
                onChange={handleChange}
                errorMessage={errors['companyData.email']}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Select
                disabled={isEditing}
                label="Empresa é o grupo econômico?"
                name="companyData.businessGroupInfoData.isCompanyBusinessGroup"
                options={[
                  { value: 'SIM', id: true },
                  { value: 'NÃO', id: false },
                ]}
                value={
                  form?.companyData?.businessGroupInfoData
                    ?.isCompanyBusinessGroup
                }
                onChange={(v) =>
                  handleChange({
                    target: {
                      name:
                        'companyData.businessGroupInfoData.isCompanyBusinessGroup',
                      value: v,
                    },
                  })
                }
                errorMessage={
                  errors[
                    'companyData.businessGroupInfoData.isCompanyBusinessGroup'
                  ]
                }
              />
            </ContainerFlex>
            <ContainerFlex />
          </ContainerFlex>
          <ContainerFlex marginLeft={16}>
            <ContainerFlex>
              <Input
                label="CEP"
                name="companyData.addressZipCode"
                placeholder="00000-000"
                value={form?.companyData?.addressZipCode?.masked}
                onChange={handleChange}
                maxLength={9}
                mask={MaskTypes.CEP}
                errorMessage={errors['companyData.addressZipCode.masked']}
              />
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Endereço"
                name="companyData.addressStreet"
                value={form?.companyData?.addressStreet}
                onChange={handleChange}
                errorMessage={errors['companyData.addressStreet']}
              />
            </ContainerFlex>
            <ContainerFlex row space gap="24">
              <ContainerFlex>
                <Input
                  label="Número"
                  name="companyData.addressNumber"
                  value={form?.companyData?.addressNumber}
                  onChange={handleChange}
                  errorMessage={errors['companyData.addressNumber']}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Complemento"
                  name="companyData.addressComplement"
                  value={form?.companyData?.addressComplement}
                  onChange={handleChange}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex row space gap="24">
              <ContainerFlex>
                <Input
                  label="Cidade"
                  name="companyData.addressCity"
                  value={form?.companyData?.addressCity}
                  onChange={handleChange}
                  errorMessage={errors['companyData.addressCity']}
                />
              </ContainerFlex>
              <ContainerFlex>
                <Input
                  label="Bairro"
                  name="companyData.addressDistrict"
                  value={form?.companyData?.addressDistrict}
                  onChange={handleChange}
                  errorMessage={errors['companyData.addressDistrict']}
                />
              </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex>
              <Input
                label="Estado"
                name="companyData.addressState"
                value={form?.companyData?.addressState}
                onChange={handleChange}
                errorMessage={errors['companyData.addressState']}
              />
            </ContainerFlex>
            <ContainerFlex>
              {form?.companyData?.businessGroupInfoData
                ?.isCompanyBusinessGroup ? (
                <Input
                  disabled={isEditing}
                  label="Nome do grupo econômico"
                  name="companyData.businessGroupInfoData.businessGroupData.name"
                  value={
                    form?.companyData?.businessGroupInfoData?.businessGroupData
                      ?.name
                  }
                  onChange={handleChange}
                  errorMessage={
                    errors[
                      'companyData.businessGroupInfoData.businessGroupData.name'
                    ]
                  }
                />
              ) : (
                <Select
                  disabled={isEditing}
                  label="Grupo para vincular"
                  options={groups}
                  name="companyData.businessGroupInfoData.businessGroupData.id"
                  value={
                    form?.companyData?.businessGroupInfoData?.businessGroupData
                      ?.id
                  }
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name:
                          'companyData.businessGroupInfoData.businessGroupData.id',
                        value: e,
                      },
                    })
                  }
                  errorMessage={
                    errors[
                      'companyData.businessGroupInfoData.businessGroupData.id'
                    ]
                  }
                />
              )}
            </ContainerFlex>
            <ContainerFlex>
              <Input
                disabled={isEditing}
                label="Nome fantasia"
                name="companyData.fantasyName"
                value={form?.companyData?.fantasyName}
                onChange={handleChange}
                errorMessage={errors['companyData.fantasyName']}
              />
            </ContainerFlex>
          </ContainerFlex>
        </Grid>

        {form?.companyData?.businessGroupInfoData?.isCompanyBusinessGroup &&
        !state?.document ? (
          <ContainerFlex marginTop={40} marginBottom={30}>
            <ContainerFlex>
              <h3>Parâmetros gerais</h3>

              <GeneralData
                onChange={(values) =>
                  setForm((prev) => ({
                    ...prev,
                    companyData: {
                      ...prev.companyData,
                      generalParametersData: values,
                    },
                  }))
                }
                defaultValues={form?.companyData?.generalParametersData}
              />
            </ContainerFlex>
            <ContainerFlex>
              <h3>Dados da conta domicílio</h3>

              <BankData
                onChange={(values) =>
                  setForm((prev) => ({
                    ...prev,
                    bankData: {
                      ...values,
                    },
                  }))
                }
                defaultValues={form?.bankData}
              />
            </ContainerFlex>
            <ContainerFlex>
              <h3>Taxa de administração (MDR)</h3>

              <MDRData
                onChange={(values) =>
                  setForm((prev) => ({
                    ...prev,
                    companyData: {
                      ...prev.companyData,
                      splitsModalities: values,
                    },
                  }))
                }
                defaultValues={form.companyData?.splitsModalities}
              />
            </ContainerFlex>
          </ContainerFlex>
        ) : null}

        <ContainerFlex row end marginTop={30} gap="30">
          <Button outlined fitContent onClick={() => history.goBack()}>
            Voltar
          </Button>
          {state?.document && form?.companyData?.cnpj && (
            <Button onClick={handleResendWelcomeEmail}>Reenviar e-mail de boas-vindas</Button>
          )}
          <Button
            fitContent
            onClick={handleSubmit}
            // disabled={isButtonDisabled()}
          >
            {state?.document && form?.companyData?.cnpj ? (
              <span>Salvar</span>
            ) : (
              <span>Cadastrar</span>
            )}
          </Button>
        </ContainerFlex>
      </ContainerFlex>
    </>
  );
};

export default CPFEstabilishment;
