import styled from 'styled-components';

export const BoxCard = styled.div`
  width: 100%;
  padding: 24px;
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  margin-bottom: 24px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px;
  text-align: center;
  margin: 20px auto;
`;

export const Thead = styled.thead`
  border-radius: 4px;
  line-height: 30px;
  background: #f9f9f9;
  table-layout: fixed;
`;

export const TBody = styled.tbody`
  line-height: 70px;
`;
export const TheadRow = styled.tr`
  border-bottom: 2px solid black;
`;

export const Th = styled.th`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 8px;
  color: #495057;
  word-break: break-word;
  line-height: 50px;
  border-bottom: 1px solid #eee;
`;

export const TbodyRow = styled.tr`
  border-bottom: 1px solid black;
  box-shadow: 0px 1px 1px -1px #999;
`;

export const Td = styled.td`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #4a4b4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    > span {
      font-size: 1rem;
    }
  }
`;

export const TableInput = styled.input`
  border: none;
  box-shadow: 0px 1px 4px 0px #999;
  border-radius: 4px;
  height: 40px;
  width: 90px;
  text-align: center;
`;
