/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import React, { InputHTMLAttributes, useState } from 'react';
import Bullet from '~/components/bullet';
import ContainerFlex from '~/components/containerFlex';
import { icons } from '~/assets';
// import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import * as Masks from '~/utils/masks';
import * as St from './styles';

export enum MaskTypes {
  CPF = 'cpfMask',
  NAME = 'nameFormatter',
  CEP = 'cepMask',
  CNPJ = 'cnpjMask',
  DATE = 'dateMask',
  HOUR_MIN_SEC = 'hourMinSecMask',
  PHONE = 'phoneMask',
  CREDIT_CARD = 'creditCardMask',
  NUMERIC = 'numericMask',
  CURRENCY = 'currencyMask',
  EMAIL = 'emailMask',
}

export type InputProps = {
  bulletColor?: string;
  name?: string;
  id?: string;
  label?: string;
  labelFor?: string;
  icon?: any;
  initialValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  error?: string;
  errorDiv?: boolean;
  edit?: boolean;
  mask?: MaskTypes;
  value?: string | number;
  placeholder?: string;
  blind?: boolean;
  defaultValue?: string | number;
  onChange?: (e: any) => void;
  labelStyle?: React.CSSProperties;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  label,
  labelFor = '',
  name,
  placeholder,
  disabled = false,
  errorDiv = false,
  value,
  errorMessage,
  onChange = (e) => null,
  edit,
  mask,
  blind,
  bulletColor = null,
  defaultValue,
  labelStyle,
  ...props
}: InputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const [isBlinded, setIsBlinded] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (mask && value !== '') {
      const valueMasked = Masks[mask](value as never);

      return onChange({
        target: {
          value: valueMasked,
          name,
        },
      });
    }

    return onChange(e);
  };

  return (
    <St.Container isFocused={isFocused} error={errorDiv || !!errorMessage}>
      <ContainerFlex width={100} space>
        {!!label && (
          <label style={labelStyle} htmlFor={labelFor}>
            {label}
          </label>
        )}
        <ContainerFlex row space>
          <input
            type={blind && isBlinded ? 'password' : 'text'}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
            value={value}
            name={name}
            placeholder={placeholder}
            {...props}
          />
          {edit && <St.IconRight src={icons.inputEdit} />}
          {blind && (
            <St.IconRight
              id="eye"
              src={isBlinded ? icons.noBlind : icons.blind}
              onClick={() => setIsBlinded((prev) => !prev)}
            />
          )}
        </ContainerFlex>
      </ContainerFlex>

      {errorMessage && <St.ErrorMessage>{errorMessage}</St.ErrorMessage>}
    </St.Container>
  );
};

export default Input;
