import { useEffect, useState } from 'react';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/newInput';
import banknowApi from '~/services/banknow/api';
import BANKNOW_ENDPOINTS from '~/services/banknow/endpoints';
import { BankData as BD } from '~/services/banknow/types';

const BankData = ({
  defaultValues,
  onChange,
}: {
  defaultValues: BD;
  onChange: (values: BD) => void;
}) => {
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    onChange(values);
  }, [values]);
  const updateNestedValue = (obj, path, value) => {
    if (!path.length) return value;
    const [key, ...rest] = path;
    return { ...obj, [key]: updateNestedValue(obj[key] || {}, rest, value) };
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => updateNestedValue(prev, name.split('.'), value));
  };
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    const getBanks = async () => {
      const response = await banknowApi.post<{
        banks: { code: number; fullName: string; ispb: string }[];
      }>(BANKNOW_ENDPOINTS.ACCOUNTS.postGetBanks, { fullName: '' });
      setBanks(response.data?.banks);
    };
    getBanks();
  }, []);
  return (
    <ContainerFlex gap={12} row space marginTop={20}>
      <ContainerFlex>
        <Input
          name="accountHolder.holderName"
          label="Nome Titular"
          value={values?.accountHolder?.holderName}
          onChange={handleChange}
        />
        <AutoCompleteSelect
          name="bank"
          label="Banco"
          value={values?.bankName}
          onChange={(op) => {
            console.log(op);
            setValues((prev) => ({
              ...prev,
              bank: op.ispb,
              code: op.id ? +op.id : '',
              bankName: op.value,
            }));
          }}
          options={banks?.map((b) => ({
            id: b.code,
            value: b.fullName,
            ispb: b.ispb,
          }))}
        />
        <Input
          name="account"
          label="Conta"
          value={values?.account}
          onChange={handleChange}
        />
      </ContainerFlex>
      <ContainerFlex>
        <Input
          name="accountHolder.cpfCnpj"
          label="CPF/CNPJ Titular"
          mask={
            values.accountHolder?.cpfCnpj?.unmasked?.length > 11
              ? MaskTypes.CNPJ
              : MaskTypes.CPF
          }
          value={values?.accountHolder?.cpfCnpj?.masked}
          onChange={handleChange}
        />

        <Input
          name="agency"
          label="Agência"
          value={values?.agency}
          onChange={handleChange}
        />
        <Input
          name="digit"
          label="Dígito"
          value={values?.digit}
          onChange={handleChange}
        />
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default BankData;
