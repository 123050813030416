/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Container from '~/components/container';
import * as St from '../styles';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/newInput';
import Checkbox from '~/components/checkbox';
import Button from '~/components/button';
import {
  cepMask,
  cnpjMask,
  cpfMask,
  dateMask,
  IDefaultReturn,
  phoneMask,
} from '~/utils/masks';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Tabs from '~/components/tab';
import banknowApi from '~/services/banknow/api';
import BANKNOW_ENDPOINTS from '~/services/banknow/endpoints';
import {
  CompanyDataBodyRequest,
  CompanyDataResponse,
  GeneralParametersData,
  SplitsModality,
} from '~/services/banknow/types';
import GeneralData from '../../parameters/components/GeneralData';
import BankData from '../../parameters/components/BankData';
import MDRData from '../../parameters/components/MDRData';
import CPFEstablishment from './components/CPFEstabilishment';
import CNPJEstablishment from './components/CNPJEstabilishment';

interface Address {
  cep?: string;
  logradouro?: string;
  numero?: string;
  cidade?: string;
  bairro?: string;
  complemento?: string;
  uf?: string;
  localidade?: string;
  pais?: string;
}

export interface CompanyDetailForm extends CompanyDataBodyRequest {
  nome?: string;
  groupInfo: {
    isGroup: boolean;
    groupName?: string;
    groupId?: string;
  };
  cpf?: IDefaultReturn;
  cnpj?: IDefaultReturn;
  telefone?: IDefaultReturn;
  dataNascimento?: IDefaultReturn;
  razaoSocial?: string;
  email?: string;
  nomeFantasia?: string;
  cep?: IDefaultReturn;
  logradouro?: string;
  numero?: string;
  cidade?: string;
  bairro?: string;
  complemento?: string;
  uf?: string;
  localidade?: string;
  pais?: string;
  representanteLegal?: string;
  cargoRepresentante?: string;
  cpfRepresentante?: IDefaultReturn;
  telefoneRepresentanteLegal?: IDefaultReturn;
  emailRepresentanteLegal?: string;
  dataNascimentoRepresentante?: IDefaultReturn;
  parameters?: {
    general: GeneralParametersData;
    bankData: BankData;
    splits: SplitsModality[];
  };
}

interface IEstablishment {
  nome?: string;
  cpf?: string;
  cnpj?: string;
  telefone?: string;
  dataNascimento?: string;
  email?: string;
  nomeFantasia?: string;
  endereco?: Address;
  representanteLegal?: string;
  cargoRepresentante?: string;
  cpfRepresentanteLegal?: string;
  dataNascimentoRepresentante?: string;
  telefoneRepresentanteLegal?: string;
  emailRepresentanteLegal?: string;
}

interface IState {
  id: string;
  document?: string;
}

type TypeOfPerson = 'fisica' | 'juridica' | null;

const initialData: CompanyDataBodyRequest = {
  companyData: {
    splitsModalities: [
      {
        modality: 'A_VISTA',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_2X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_3X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_4X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_5X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_6X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_7X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_8X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_9X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_10X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_11X',
        percentualSplit: 0,
      },
      {
        modality: 'PARCELADO_12X',
        percentualSplit: 0,
      },
    ],
    businessGroupInfoData: {
      isCompanyBusinessGroup: false,
      businessGroupData: {
        name: '',
        id: '',
      },
    },
  },
  bankData: {
    accountHolder: { cpfCnpj: '', holderName: '' },
    account: '',
    agency: '',
    bank: '',
    bankName: '',
    code: '',
    digit: '',
    type: '',
  },
  responsiblesData: {
    cpf: '',
    dateOfBirth: '',
    email: '',
    name: '',
    phone: '',
    role: '',
    surname: '',
  },
};
const RegisterTeuPagEstablishment = () => {
  const [form, setForm] = useState<CompanyDataBodyRequest>(initialData);
  const [typeOfPerson, setTypeOfPerson] = useState<TypeOfPerson>(null);
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { state } = useLocation<IState>();

  console.log(state);

  const isEditing = !!state?.id || !!state?.document;

  const RequestGetDetailsEstablishment = useApi<IEstablishment>(
    Endpoints.establishments.getDetailEstablishments.replace(
      'PARAM_ID',
      state?.id,
    ),
    ApiMethod.GET,
  );

  const GetAddressByCep = useApi<Address>(
    Endpoints.consult.getAddressByCep.replace(
      'PARAM_CEP',
      form?.addressZipCode?.unmasked,
    ),
    ApiMethod.GET,
  );

  const getDetailsEstablishments = async () => {
    try {
      setLoading(true);
      const response = await banknowApi.get<CompanyDataResponse>(
        BANKNOW_ENDPOINTS.COMPANIES.getCompanyDetails(state.document),
      );

      // const response = await RequestGetDetailsEstablishment.callApi();
      const data2 = {
        companyData: {
          cnpj: '69983386000541',
          tradeName: 'trade bname breno 232323232',
          fantasyName: 'Nome fantasia teste breno 2',
          email: 'emailbrenoopahit@gmail.com',
          phone: '61999999999',
          addressStreet: 'rua teste',
          addressNumber: '12',
          addressComplement: 'perto do vegas',
          addressDistrict: 'valparaiso 1',
          addressCity: 'Valparaíso de goiás',
          addressState: 'Goiás',
          addressZipCode: '72876128',
          addressCountry: 'Brasil',
          id: 'string',
          category: 'CLINIC',
          operationLimit: 0,
          packageData: {
            mechantDiscountRate: 0,
            clientUCC: 0,
          },
          businessGroupInfoData: {
            isCompanyBusinessGroup: true,
            businessGroupData: {
              name: 'teste asdasd 22323',
            },
          },
          generalParametersData: {
            mcc: 5598,
            captureMethod: 'POS TEUPAG',
            posQuantity: 32,
            monthlyCardTotalRevenue: 150,
            repassCondition: 'ANTECIPADO',
          },
          splitsModalities: [
            {
              modality: 'A_VISTA',
              percentualSplit: 1,
            },
            {
              modality: 'PARCELADO_2X',
              percentualSplit: 2,
            },
            {
              modality: 'PARCELADO_3X',
              percentualSplit: 2,
            },
            {
              modality: 'PARCELADO_4X',
              percentualSplit: 2,
            },
            {
              modality: 'PARCELADO_5X',
              percentualSplit: 2,
            },
            {
              modality: 'PARCELADO_6X',
              percentualSplit: 3.5,
            },
            {
              modality: 'PARCELADO_7X',
              percentualSplit: 3.5,
            },
            {
              modality: 'PARCELADO_8X',
              percentualSplit: 3.5,
            },
            {
              modality: 'PARCELADO_9X',
              percentualSplit: 3.5,
            },
            {
              modality: 'PARCELADO_10X',
              percentualSplit: 5,
            },
            {
              modality: 'PARCELADO_11X',
              percentualSplit: 5,
            },
            {
              modality: 'PARCELADO_12X',
              percentualSplit: 8,
            },
          ],
        },
        bankData: [
          {
            accountHolder: {
              holderName: 'Dono da conta bancaria',
              cpfCnpj: '00000000076',
            },
            bankName: 'Santander S.A',
            agency: '1234',
            account: '12341232',
            digit: '2',
            bank: '341',
            code: 224,
            type: 'CHECKING',
          },
        ],
        responsiblesData: [
          {
            name: 'João macedo',
            surname: 'delgado',
            cpf: '03788877776',
            email: 'macedojoao@opahit.com',
            phone: '61999999998',
            role: 'SOCIO',
            dateOfBirth: '10/12/1993',
          },
        ],
      };
      // const { data } = response;
      // const data: CompanyDataResponse = {
      //   active: true,
      //   address: [
      //     {
      //       address: 'rua teste',
      //       addressId: '67c0c05840adb57b3324db78',
      //       addressType: 'COMMERCIAL',
      //       cityName: 'Valparaíso de goiás',
      //       complement: 'perto do vegas',
      //       country: 'Brasil',
      //       distance: 0,
      //       neighborhood: 'valparaiso 1',
      //       number: '12',
      //       state: 'Goiás',
      //       zipCode: '72876128',
      //     },
      //   ],
      //   businessGroups: [
      //     {
      //       name: 'Grupo de negocios que tera mais de uma company',
      //     },
      //   ],
      //   category: {
      //     name: 'CLINIC',
      //   },
      //   contacts: [
      //     {
      //       cellPhone: '61999999998',
      //       contactId: '67c0c05940adb57b3324db79',
      //       email: 'macedojoao@opahit.com',
      //       name: 'João macedo delgado',
      //       occupation: 'Gerente',
      //     },
      //   ],
      //   document: '61255126100541',
      //   email: 'emailbrenoopahit@gmail.com',
      //   generalParameters: {
      //     captureMethod: 'POS TEUPAG',
      //     mcc: 5598,
      //     monthlyCardTotalRevenue: 150,
      //     posQuantity: 32,
      //     repassCondition: 'ANTECIPADO',
      //   },
      //   homeAccount: {
      //     account: '12341232',
      //     accountHolder: {
      //       cpfCnpj: '00000000076',
      //       holderName: 'Dono da conta bancaria',
      //     },
      //     accountType: 'CHECKING_ACCOUNT',
      //     bankCode: '224',
      //     bankFullName: 'Santander S.A',
      //     bankIspb: '341',
      //     branch: '1234',
      //     digit: '2',
      //   },
      //   id: '67c0c05740adb57b3324db75',
      //   integrationId: 'string',
      //   name: 'MUDOU O NAME DA COMPANY',
      //   nickname: 'Nome fantasia teste breno 2',
      //   splitsModalities: [
      //     {
      //       percentual: 1,
      //       splitPayment: 'A_VISTA',
      //     },
      //     {
      //       percentual: 0.01,
      //       splitPayment: 'PARCELADO_2X',
      //     },
      //     {
      //       percentual: 2,
      //       splitPayment: 'PARCELADO_3X',
      //     },
      //     {
      //       percentual: 2,
      //       splitPayment: 'PARCELADO_4X',
      //     },
      //     {
      //       percentual: 2,
      //       splitPayment: 'PARCELADO_5X',
      //     },
      //     {
      //       percentual: 3.5,
      //       splitPayment: 'PARCELADO_6X',
      //     },
      //     {
      //       percentual: 3.5,
      //       splitPayment: 'PARCELADO_7X',
      //     },
      //     {
      //       percentual: 3.23,
      //       splitPayment: 'PARCELADO_8X',
      //     },
      //     {
      //       percentual: 3.45,
      //       splitPayment: 'PARCELADO_9X',
      //     },
      //     {
      //       percentual: 5,
      //       splitPayment: 'PARCELADO_10X',
      //     },
      //     {
      //       percentual: 5.33,
      //       splitPayment: 'PARCELADO_11X',
      //     },
      //     {
      //       percentual: 8.9,
      //       splitPayment: 'PARCELADO_12X',
      //     },
      //   ],
      // };
      const { data } = response;
      const address = data.address[0];
      const contact = data.contacts[0];
      const bank = data.homeAccount;
      const businessGroup = data.businessGroups[0];

      const cnpjObject = {
        companyData: {
          tradeName: data.name,
          fantasyName: data.nickname,
          email: data.email,
          addressStreet: address.address,
          addressNumber: address.number,
          addressComplement: address.complement,
          addressDistrict: address.neighborhood,
          addressCity: address.cityName,
          addressState: address.state,
          addressZipCode: cepMask(address.zipCode),
          cnpj: cnpjMask(data?.document),
          phone: phoneMask(data?.phone),
          cpf: cpfMask(data?.document),
          addressCountry: address.country,
          id: data.id,
          category: data.category.name,
          operationLimit: 0,
          packageData: {
            merchantDiscountRate: 0,
            clientUCC: 0,
          },
          businessGroupInfoData: {
            isCompanyBusinessGroup: data.isBusinessGroup,
            businessGroupData: businessGroup,
          },
          generalParametersData: data.generalParameters
            ? {
                mcc: data.generalParameters.mcc,
                captureMethod: data.generalParameters.captureMethod,
                posQuantity: data.generalParameters.posQuantity,
                monthlyCardTotalRevenue:
                  data.generalParameters.monthlyCardTotalRevenue,
                repassCondition: data.generalParameters.repassCondition,
              }
            : {},
          splitsModalities: data.splitsModalities?.length
            ? data.splitsModalities.map((split) => ({
                modality: split.splitPayment,
                percentualSplit: split.percentual,
              }))
            : initialData.companyData.splitsModalities,
        },
        bankData: !!bank
          ? {
              accountHolder: {
                holderName: bank.accountHolder.holderName,
                cpfCnpj:
                  bank.accountHolder.cpfCnpj.length > 11
                    ? cnpjMask(bank.accountHolder.cpfCnpj)
                    : cpfMask(bank.accountHolder.cpfCnpj),
              },
              bankName: bank.bankFullName,
              agency: bank.branch,
              account: bank.account,
              digit: bank.digit,
              bank: bank.bankIspb,
              code: bank.bankCode,
              type: bank.accountType.replace('_', ''),
            }
          : {},

        responsiblesData: {
          name: contact.name,
          surname: contact.name.split(' ').slice(1).join(' '),
          cpf: cpfMask(contact?.cpf),
          email: contact.email,
          phone: phoneMask(contact.cellPhone),
          occupation: contact.occupation,
          dateOfBirth: dateMask(
            moment(contact?.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          ),
        },
      };
      const cpfObject = {
        companyData: {
          tradeName: data.name,
          fantasyName: data.nickname,
          email: data.email,
          addressStreet: address.address,
          addressNumber: address.number,
          addressComplement: address.complement,
          addressDistrict: address.neighborhood,
          addressCity: address.cityName,
          addressState: address.state,
          addressZipCode: cepMask(address.zipCode),
          cnpj: cpfMask(data?.document),
          phone: phoneMask(contact?.cellPhone),
          cpf: cpfMask(data?.document),
          addressCountry: address.country,
          id: data.id,
          category: data.category.name,
          operationLimit: 0,
          packageData: {
            merchantDiscountRate: 0,
            clientUCC: 0,
          },
          businessGroupInfoData: {
            isCompanyBusinessGroup: data.isBusinessGroup,
            businessGroupData: businessGroup,
          },
          generalParametersData: data.generalParameters
            ? {
                mcc: data.generalParameters.mcc,
                captureMethod: data.generalParameters.captureMethod,
                posQuantity: data.generalParameters.posQuantity,
                monthlyCardTotalRevenue:
                  data.generalParameters.monthlyCardTotalRevenue,
                repassCondition: data.generalParameters.repassCondition,
              }
            : {},
          splitsModalities: data.splitsModalities?.length
            ? data.splitsModalities.map((split) => ({
                modality: split.splitPayment,
                percentualSplit: split.percentual,
              }))
            : initialData.companyData.splitsModalities,
        },
        bankData: !!bank
          ? {
              accountHolder: {
                holderName: bank.accountHolder.holderName,
                cpfCnpj:
                  bank.accountHolder.cpfCnpj.length > 11
                    ? cnpjMask(bank.accountHolder.cpfCnpj)
                    : cpfMask(bank.accountHolder.cpfCnpj),
              },
              bankName: bank.bankFullName,
              agency: bank.branch,
              account: bank.account,
              digit: bank.digit,
              bank: bank.bankIspb,
              code: bank.bankCode,
              type: bank.accountType.replace('_', ''),
            }
          : {},

        responsiblesData: {
          name: contact.name,
          surname: contact.name.split(' ').slice(1).join(' '),
          cpf: cpfMask(contact?.cpf),
          email: contact.email,
          phone: phoneMask(contact.cellPhone),
          occupation: contact.occupation,
          dateOfBirth: dateMask(
            moment(contact?.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          ),
        },
      };

      if (response.status === 200) {
        const isPf = data?.companyType === 'PESSOA_FISICA';
        setForm(isPf ? cpfObject : cnpjObject);

        setTypeOfPerson(isPf ? 'fisica' : 'juridica');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleGetCep = async () => {
    const response = await GetAddressByCep.callApi();
    if (response.status >= 200 && response.status <= 300) {
      setForm((prev) => ({
        ...prev,
        logradouro: response?.data?.logradouro,
        bairro: response?.data?.bairro,
        cidade: response?.data?.localidade,
        uf: response?.data?.uf,
      }));
    }
  };

  useEffect(() => {
    if (form?.addressZipCode?.unmasked?.length === 8) {
      handleGetCep();
    }
  }, [form?.addressZipCode]);

  useEffect(() => {
    if (state?.document) {
      getDetailsEstablishments();
    }
  }, [state?.document]);

  const handleInitialTab = (person) => {
    if (person === 'juridica') {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <Loading
        status={
          RequestGetDetailsEstablishment.loading ||
          GetAddressByCep.loading ||
          loading
        }
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogError}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogError(false);
          history.push('/teu-pag/estabelecimento');
        }}
        message={messageError}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogMessage}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogMessage(false);
        }}
        message={messageError}
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <Tabs initialTab={handleInitialTab(typeOfPerson)}>
              <Tabs.Tab
                label="Pessoa Física"
                disabled={typeOfPerson === 'juridica' && Boolean(state?.id)}
              >
                <CPFEstablishment form={form} setForm={setForm} />
              </Tabs.Tab>
              <Tabs.Tab
                label="Pessoa Jurídica"
                disabled={typeOfPerson === 'fisica' && Boolean(state?.id)}
              >
                <CNPJEstablishment form={form} setForm={setForm} />
              </Tabs.Tab>
            </Tabs>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default RegisterTeuPagEstablishment;
