import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Container from '~/components/container';
import * as St from '../../styles';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input, { MaskTypes } from '~/components/input';
import Checkbox from '~/components/checkbox';
import Button from '~/components/button';
import {
  cepMask,
  cnpjMask,
  cpfMask,
  dateMask,
  IDefaultReturn,
  phoneMask,
} from '~/utils/masks';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import Loading from '~/components/loading';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Tabs from '~/components/tab';

interface IForm {
  cpf?: IDefaultReturn;
  nome?: string;
  telefone?: IDefaultReturn;
  email?: string;
  dataNascimento?: IDefaultReturn;
  cargo?: string;
}

interface IUserMaster {
  cpfCnpj?: string;
  nomeCompleto?: string;
  telefone?: string;
  email?: string;
  dataNascimento?: string;
  cargo?: string;
}

interface IState {
  id: string;
}

const RegisterUserMaster: React.FC = () => {
  const [form, setForm] = useState<IForm>();
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const history = useHistory();

  const { state } = useLocation<{ id: string }>();

  console.log('StateId: ', state?.id);

  const RequestGetUserMasterData = useApi<IUserMaster>(
    Endpoints.establishments.getUserMasterData.replace('PARAM_ID', state?.id),
    ApiMethod.GET,
  );

  useEffect(() => {
    if (state?.id) {
      getUserMasterData();
    }
  }, [state?.id]);

  const getUserMasterData = async () => {
    try {
      const response = await RequestGetUserMasterData.callApi();
      const { data } = response;
      if (response.status === 200) {
        setForm({
          cpf: cpfMask(data.cpfCnpj),
          nome: data?.nomeCompleto,
          telefone: phoneMask(data.telefone),
          email: data.email,
          dataNascimento: data?.dataNascimento
            ? {
                masked: moment(data?.dataNascimento, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY',
                ),
                unmasked: data?.dataNascimento,
              }
            : null,
          cargo: data?.cargo,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    // if(handleErrors)
    if (true) {
      const payload = {
        estabelecimentoId: state?.id,
        cpfCnpj: form?.cpf?.unmasked,
        email: form?.email,
        nomeCompleto: form?.tradeName,
        dataNascimento: moment(
          form?.dataNascimento?.masked,
          'DD/MM/YYYY',
        ).format('YYYY-MM-DD'),
        telefone: form?.phone?.unmasked,
        cargo: form?.cargo,
      };

      try {
        const response = state?.id
          ? await RequestCreateUserMasterAccessPortal.callApi(payload)
          : await RequestCreateUserMasterAccessPortal.callApi(payload);
        // criar endpoint edit

        if (response.status === 200) {
          setDialogError(true);
          setMessageError(
            state?.id
              ? 'Cliente editado com sucesso'
              : 'Cliente criado com sucesso',
          );
        } else if (response.status >= 404) {
          setDialogError(true);
          setMessageError(response.data.mensagem);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const RequestCreateUserMasterAccessPortal = useApi(
    Endpoints.establishments.postCreateUserMasterAccessPortal,
    ApiMethod.POST,
  );

  const RequestEditEstablishment = useApi(
    Endpoints.establishments.putEditEstablishment.replace(
      'PARAM_ID',
      state?.id,
    ),
    ApiMethod.PUT,
  );

  // const handleErrors = () => {
  //   const requiredFields = [
  //     'cpf',
  //     'email',
  //     'nome',
  //     'dataNascimento',
  //     'telefone',
  //     'cep',
  //     'logradouro',
  //     'cidade',
  //     'bairro',
  //     'numero',
  //   ];

  //   const newErrors: { [key: string]: string } = {};

  //   const fieldsWithUnmasked = ['cpf', 'telefone', 'cep', 'dataNascimento'];

  //   requiredFields.forEach((field) => {
  //     const value = (form as any)[field];

  //     if (fieldsWithUnmasked.includes(field)) {
  //       if (!value || !value.unmasked) {
  //         newErrors[field] = `${
  //           field.charAt(0).toUpperCase() + field.slice(1)
  //         } é obrigatório.`;
  //       }
  //     } else if (!value || (typeof value === 'string' && value.trim() === '')) {
  //       newErrors[field] = `${
  //         field.charAt(0).toUpperCase() + field.slice(1)
  //       } é obrigatório.`;
  //     }
  //   });

  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  const isButtonDisabled = () => {
    return false;
  };

  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Loading status={RequestGetUserMasterData.loading} />
      {/* <Dialog
        open={openDialogEmail}
        message={msgDialogEmail}
        positiveAction={() => setOpenDialogEmail(false)}
        positiveLabel="Entendi"
        icon={statusEmail ? icons.check : icons.warning}
      />

      <Dialog
        icon={icons.exclamation}
        open={dialogResetPassword}
        negativeLabel="Não"
        negativeAction={() => setDialogResetPassword(false)}
        positiveLabel="Sim"
        positiveAction={() =>
          setModalPasswordOpen({ open: true, type: 'resetPassword' })
        }
        message="Deseja enviar o e-mail para redefinir a senha?"
        Loading={RequestResetPassword.loading}
      >
        {modalPasswordOpen.type === 'resetPassword' ? (
          <PasswordModal
            onClose={() => setModalPasswordOpen({ open: false, type: '' })}
            onSuccessValidation={() => handleResetPassword()}
            Loading={RequestResetPassword.loading}
          />
        ) : null}
      </Dialog>

      <Dialog
        open={openDialog}
        message={dialogMessage}
        positiveAction={async () => {
          await getUserData();
          handleToggleDialog();
        }}
        positiveLabel="Entendi"
        icon={icons.check}
      />

      <Dialog
        open={openDialogBlock}
        widthInPercent={30}
        title={!form.bloqueado ? 'Bloquear acesso' : 'Desbloquear acesso'}
        message={
          <div style={{ textAlign: 'center' }}>
            {!form.bloqueado ? (
              <span>
                O usuário perderá acesso a <b>qualquer aplicação</b>, até que
                seja desbloqueado!
              </span>
            ) : (
              <span>
                O usuário ganhará acesso ao <b>qualquer aplicação</b>, até que
                seja bloqueado!
              </span>
            )}
            <p style={{ marginTop: '2rem' }}>Deseja continuar?</p>
          </div>
        }
        positiveAction={() =>
          form.bloqueado ? handleUnblockUser() : handleBlockUser()
        }
        negativeAction={toogleDialogBlock}
        positiveLabel="Continuar"
        buttonDisabledPositive={
          PostBlockUser.loading || PostUnblockUser.loading
        }
        negativeLabel="Voltar"
        icon
      /> */}

      <Container>
        <St.BoxCard>
          <ContainerFlex>
            <Grid xs={1} lg={2} gap={20}>
              <ContainerFlex padding={16}>
                <Input
                  label="CPF"
                  name="cpf"
                  maxLength={14}
                  mask={MaskTypes.CPF}
                  onChange={handleChange}
                  placeholder="000.000.000-00"
                  value={form?.cpf?.masked}
                />
                <Input
                  label="Nome completo"
                  name="nome"
                  value={form?.tradeName}
                  onChange={handleChange}
                  errorMessage={errors.nome}
                  autoComplete="off"
                />
                <Input
                  label="Telefone"
                  name="companyData.phone"
                  mask={MaskTypes.PHONE}
                  value={form?.phone?.masked}
                  placeholder="(00) 00000-0000"
                  maxLength={15}
                  onChange={handleChange}
                />
              </ContainerFlex>
              <ContainerFlex padding={16}>
                <Input
                  label="Data de nascimento"
                  name="dataNascimento"
                  mask={MaskTypes.DATE}
                  maxLength={10}
                  value={form?.dataNascimento?.masked}
                  onChange={handleChange}
                />
                <Input
                  label="E-mail"
                  name="companyData.email"
                  value={form?.email}
                  onChange={handleChange}
                  errorMessage={errors?.email}
                />
                <Input
                  label="Cargo"
                  name="cargo"
                  value={form?.cargo}
                  onChange={handleChange}
                  errorMessage={errors.cargo}
                />
              </ContainerFlex>
            </Grid>
            <ContainerFlex gap="16" row end wrap>
              <Button outlined onClick={() => history.goBack()}>
                Voltar
              </Button>
              <Button>Reenviar senha</Button>
              <Button
                fitContent
                onClick={handleSubmit}
                disabled={isButtonDisabled()}
              >
                {state?.id ? <span>Salvar</span> : <span>Cadastrar</span>}
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default RegisterUserMaster;
