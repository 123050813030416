/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '~/components/container';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Button from '~/components/button';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Endpoints from '~/services/endpoints';
import { cpfCnpjMask, IDefaultReturn, phoneMask } from '~/utils/masks';
import { IListCol } from '~/components/listView/row/col';
import TableList from '~/components/tableList';
import Pagination from '~/components/pagination';
import Loading from '~/components/loading';
import { IListColumn } from '~/models/listCol';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import banknowApi from '~/services/banknow/api';
import BANKNOW_ENDPOINTS from '~/services/banknow/endpoints';
import * as St from './styles';

interface Address {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  logradouro: string;
  numero: string;
  pais: string;
  uf: string;
}

interface IEstablishment {
  cpf: string;
  cnpj: string;
  email: string;
  endereco: Address;
  id: string;
  telefone: string;
}

interface IEstablishmentResponse {
  currentPage: number;
  data: IEstablishment[];
  pageSize: number;
  totalItens: number;
  totalPages;
}

interface IForm {
  name: string;
  code: IDefaultReturn;
}

const TeuPagEstablishment = () => {
  const [form, setForm] = useState<IForm>({
    code: { masked: '', unmasked: '' },
    name: '',
  } as IForm);
  const [idEstablishment, setIdEstablishment] = useState('');
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const RequestSearchEstablishments = useApi<IEstablishmentResponse>(
    `${Endpoints.establishments.getSeacrhEstablishments}?cpfCnpj=${
      form.cpfCnpj?.unmasked ? form.cpfCnpj.unmasked : ''
    }&nome=${form?.name ? form.name : ''}&page=1&size=10`,
    ApiMethod.GET,
  );
  const getSearchEstablishments = async (params?) => {
    setIsLoading(true);
    const formattedParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => !!value),
    );
    const p = new URLSearchParams({
      limit: 10,
      page: currentPage,
      ...formattedParams,
    });
    const response = await banknowApi.get(
      BANKNOW_ENDPOINTS.COMPANIES.getCompanies(p),
    );
    setIsLoading(false);

    if (response.status === 200) {
      const {
        data,
        totalSize,
        page,
        pageSize,
        totalPages,
      }: IEstablishmentResponse = response.data;
      setCurrentPage(page);
      setTotalPages(totalPages);
      return data;
    }
  };
  useEffect(() => {
    handleSubmit();
  }, [currentPage]);

  const RequestDeleteEstablishments = useApi(
    Endpoints.establishments.deleteEstablishments.replace(
      'PARAM_ID',
      idEstablishment,
    ),
    ApiMethod.DELETE,
  );

  console.log(idEstablishment);

  const customHeader = [
    {
      id: 1,
      value: 'Nome/Razão Social',
      maxChar: 20,
    },
    {
      id: 2,
      value: 'CPF/CNPJ',
      maxChar: 18,
    },
    {
      id: 3,
      value: 'E-mail',
      maxChar: 15,
    },
    {
      id: 4,
      value: 'Celular',
      maxChar: 15,
    },
    {
      id: 5,
      value: 'Ação',
      maxChar: 10,
    },
    // {
    //   id: 6,
    //   value: 'Excluir',
    //   maxChar: 10,
    // },
  ];

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const isButtonDisabled = useMemo(() => {
    const isCpfOrCnpj =
      form.code?.masked?.length === 14 || form.code?.masked?.length === 18;

    const isNameFilled = form.name && form.name.trim().length > 0;

    return !(isCpfOrCnpj || isNameFilled);
  }, [form]);

  const handleSubmit = async () => {
    const data = await getSearchEstablishments({
      code: form.code.unmasked ?? null,
      name: form.name ?? null,
    });

    if (data) {
      const newList = [];

      data.forEach((data) => {
        const items: IListColumn[] = [
          {
            border: true,
            title: 'Nome/Razão social',
            value: data.name,
            maxChar: 20,
          },
          {
            title: 'CPF/CNPJ',
            value: data.document
              ? cpfCnpjMask(data.document).masked
              : data.cnpj
              ? cpfCnpjMask(data.document).masked
              : '',
          },
          {
            title: 'E-mail',
            value: data.email,
            maxChar: 20,
          },
          {
            title: 'Telefone',
            value: phoneMask(data?.phone).masked,
            maxChar: 15,
          },
          {
            item: data,
            searchAction: () => goToDetail(data),
          },
          // {
          //   item: data,
          //   maxChar: 5,
          //   action: (id, item) => {
          //     setIdEstablishment(id);
          //     setOpenDialogConfirmation(true);
          //   },
          // },
        ];
        newList.push(items);
      });

      setList(newList);
    }
  };

  const goToDetail = (item) => {
    history.push('/teu-pag/estabelecimento/detail', {
      ...item,
    });
  };

  const goToRegister = () => {
    history.push('/teu-pag/estabelecimento/detail');
  };

  const deleteEstablishment = async () => {
    setIsLoading(true);
    try {
      const response = await banknowApi.delete(
        BANKNOW_ENDPOINTS.COMPANIES.deleteCompany(idEstablishment),
      );
      if (response.status >= 200) {
        setOpenDialog(true);
        setOpenDialogConfirmation(false);
        setMessage('Cliente excluído com sucesso!');
        setIsLoading(false);
      } else {
        setOpenDialog(true);
        setMessage('Erro ao excluir!');
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loading status={RequestSearchEstablishments.loading || isLoading} />
      <Dialog
        open={openDialog}
        icon={icons.exclamation}
        positiveAction={() => {
          setOpenDialog(false);
          handleSubmit();
        }}
        message={message}
        positiveLabel="Entendi"
      />
      <Dialog
        icon={icons.warning}
        open={openDialogConfirmation}
        negativeAction={() => {
          setOpenDialogConfirmation(false);
        }}
        positiveAction={deleteEstablishment}
        Loading={isLoading}
        buttonDisabledPositive={RequestDeleteEstablishments.loading}
        positiveLabel="SIM"
        negativeLabel="NÃO"
        message="Deseja mesmo excluir o cliente?"
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex marginRight={16}>
              <Input
                label="Nome/Razão-social"
                value={form.name}
                onChange={(e) => {
                  setForm({
                    ...form,
                    name: e.target.value,
                  });
                }}
              />
            </ContainerFlex>
            <ContainerFlex marginRight={16}>
              <Input
                label="CPF/CNPJ"
                name="code"
                value={form.code?.masked}
                onChange={handleChange}
                mask={MaskTypes.CPF_CNPJ}
              />
            </ContainerFlex>
            <ContainerFlex row gap="30">
              <Button
                fitContent
                onClick={() => handleSubmit()}
                disabled={isButtonDisabled}
              >
                Consultar
              </Button>
              <Button fitContent onClick={goToRegister}>
                Novo cliente
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
        {list.length <= 0 ? (
          <St.BoxCard>
            <ContainerFlex center>
              <span>Cliente TeuPag não encontrado</span>
            </ContainerFlex>
          </St.BoxCard>
        ) : (
          <St.BoxCard>
            <ContainerFlex flex="0.2">
              {RequestSearchEstablishments.status === 200 && !list.length ? (
                <ContainerFlex center marginTop={30}>
                  <span>Cliente TeuPag não encontrado</span>
                </ContainerFlex>
              ) : null}
              <ContainerFlex>
                {list.length > 0 && (
                  <>
                    <ContainerFlex>
                      <TableList customerHeader={customHeader} list={list} />
                    </ContainerFlex>
                    <ContainerFlex marginTop={20} center flex={1}>
                      <Pagination
                        totalPages={totalPages}
                        initialCurrentPage={currentPage}
                        onCurrentPageChange={setCurrentPage}
                      />
                    </ContainerFlex>
                  </>
                )}
              </ContainerFlex>
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default TeuPagEstablishment;
